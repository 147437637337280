import { useEffect, useState } from "react";
import { Provider } from "react-redux";
import LayoutLoader from "@sapiens-digital/ace-designer-app/app/components/LayoutLoader";
import {
  container,
  symbols,
} from "@sapiens-digital/ace-designer-app/app/services";
import { resetRefRegistry } from "@sapiens-digital/ace-designer-app/app/services/references";
import { store } from "@sapiens-digital/ace-designer-app/app/store";
import { initializeDesignerAction } from "@sapiens-digital/ace-designer-app/app/store/designer/actions";
import { createAppender } from "@sapiens-digital/ace-designer-app/app/utils/logMemoryAppender";
import { useToken } from "login/useToken";
import { isKeycloakAuthenticationEnabled } from "login/utils";

import { executeFlow } from "./services/devApis";
import {
  getEnvVariables,
  loadDesignerSettings,
  loadWorkspaceSettings,
  saveDesignerSettings,
  saveWorkspaceSettings,
} from "./services/settings";
import {
  getEnvironment,
  getWorkspaceFS,
  getWorkspaceHttpClient,
} from "./services/workspace";
import DesignerApp from "./DesignerApp";

import "./index.css";

container.bind(symbols.LoadDesignerSettings).toFunction(loadDesignerSettings);
container.bind(symbols.SaveDesignerSettings).toFunction(saveDesignerSettings);
container.bind(symbols.LoadWorkspaceSettings).toFunction(loadWorkspaceSettings);
container.bind(symbols.SaveWorkspaceSettings).toFunction(saveWorkspaceSettings);
container.bind(symbols.GetWorkspaceFS).toFunction(getWorkspaceFS);
container.bind(symbols.GetEnvironment).toFunction(getEnvironment);
container.bind(symbols.EmitAppEvent).toFunction(() => void {});
container.bind(symbols.ExecuteFlow).toFunction(executeFlow);
container
  .bind(symbols.GetWorkspaceHttpClient)
  .toFunction(getWorkspaceHttpClient);
container.bind(symbols.GetEnvironmentVariables).toFunction(getEnvVariables);
createAppender();

resetRefRegistry();

export const App = (): JSX.Element => {
  const [isInitialized, setIsInitialized] = useState(false);
  const [showLoginPopup, setShowLoginPopup] = useState(false);
  const { token } = useToken();

  useEffect(() => {
    if (isKeycloakAuthenticationEnabled() && !token) {
      setShowLoginPopup(true);
    } else if (!isKeycloakAuthenticationEnabled() || token) {
      setShowLoginPopup(false);
      store.dispatch(initializeDesignerAction());
    }
    setIsInitialized(true);
  }, [token]);

  if (!isInitialized) {
    return <LayoutLoader />;
  }

  return (
    <Provider store={store}>
      <DesignerApp showLoginPopup={showLoginPopup} />
    </Provider>
  );
};
