import { createGitCredentialsForGit } from "@sapiens-digital/ace-designer-common/lib/helpers/git-utils";
import posixPath from "@sapiens-digital/ace-designer-common/lib/helpers/posixPath";
import git, {
  Errors,
  FetchResult,
  GetRemoteInfoResult,
  PushResult,
  StatusRow,
} from "isomorphic-git";

import { Workspace, WorkspaceFolder } from "../model/workspace";
import { removeLeadingSeparator } from "../store/utils/path";

import { serializeId } from "./references";
import { SettingsManager } from "./settingsManager";
import {
  getDirPath,
  getWorkspaceFS,
  getWorkspaceHttpClient,
  getWorkspaceLocation,
} from "./workspace";

const AUTHOR = {
  name: "ACE",
  email: "ace@sapiens.com",
};

// How these constants are utilized: https://isomorphic-git.org/docs/en/statusMatrix
enum IDX {
  FILE = 0,
  HEAD,
  WORKDIR,
  STAGE,
}

enum STATUS {
  ABSENT = 0,
  SAME_AS_HEAD,
  SAME_AS_WORKDIR,
  SAME_AS_STAGED,
}

const createGitCredentialsForRepo = (
  url: string,
  token: string,
  username?: string
) =>
  createGitCredentialsForGit(
    url,
    token,
    username,
    process.env.REACT_APP_GIT_CORS_PROXY
  );

export const GIT_ERROR_BRANCH_HAS_DIVERGED = "Branch has diverged";
export const GIT_ERROR_BRANCH_ALREADY_EXISTS =
  "Branch with the same name already exists";

// noinspection SuspiciousTypeOfGuard
export const hasAccessTokenExpired = (e: Error): boolean =>
  e instanceof TypeError && e.message === "Failed to fetch";

const BRANCH_DIVERGENCE_ERRORS = [
  Errors.PushRejectedError,
  Errors.MergeNotSupportedError,
  Errors.FastForwardError,
];

function assertNoBranchDivergence(e: Error): void {
  if (BRANCH_DIVERGENCE_ERRORS.some((Err) => e instanceof Err)) {
    throw new Error(GIT_ERROR_BRANCH_HAS_DIVERGED);
  }
}

function assertNoExpiredAccessToken(e: Error): void {
  if (hasAccessTokenExpired(e)) {
    e.message += ", verify if access token has expired";
    throw e;
  }
}

export async function gitCloneWithCredentials(
  repositoryUrl: string,
  branchOrTagName: string,
  username: string,
  password: string,
  dir: string
): Promise<void> {
  try {
    await gitCloneBase({
      dir,
      url: repositoryUrl,
      ref: branchOrTagName,
      ...createGitCredentialsForRepo(repositoryUrl, password, username),
    });
  } catch (e) {
    console.error(e);
    throw e;
  }
}

export async function pushAllEntityChangesToGit(
  workspace: Workspace,
  repositoryUrl: string,
  repositoryToken: string,
  repositoryRoot: string,
  wereExistingEntitiesErased?: boolean,
  repositoryUsername?: string
): Promise<void> {
  const fs = getWorkspaceFS();
  const dir = posixPath.join(
    SettingsManager.getWorkspacesLocation(),
    workspace.name
  );

  const filepaths = [removeLeadingSeparator(repositoryRoot)];
  const status = await git.statusMatrix({ fs, dir, filepaths });
  const isUnchanged = status.every((s) => s[IDX.HEAD] === s[IDX.WORKDIR]);

  if (isUnchanged) {
    return;
  }

  await Promise.allSettled(
    status.map((stat) => addFileByMatrix(stat, { fs, dir }))
  );

  const action = wereExistingEntitiesErased ? "Overwrote" : "Updated";
  await git.commit({
    fs,
    dir,
    author: AUTHOR,
    message: `${action} all entities`,
  });

  await gitPush(
    repositoryToken,
    {
      dir,
      url: repositoryUrl,
      ref: workspace.name,
    },
    repositoryUsername
  );
}

async function addFileByMatrix(
  stat: StatusRow,
  options: Omit<Parameters<typeof git.add>[0], "filepath">
): Promise<void> {
  const fullOptions = { ...options, filepath: stat[IDX.FILE] };

  if (stat[IDX.WORKDIR] === STATUS.ABSENT) {
    return await git.remove(fullOptions);
  }

  if (stat[IDX.WORKDIR] !== stat[IDX.HEAD]) {
    return await git.add(fullOptions);
  }
}

export type PushChangesToGit = (
  entityId: string,
  workspace: Workspace,
  folder: WorkspaceFolder,
  repositoryUrl: string,
  repositoryToken: string,
  oldName?: string,
  repositoryUsername?: string
) => Promise<void>;

export const pushDeletedFolderToGit: PushChangesToGit = async (
  folderId: string,
  workspace: Workspace,
  folder: WorkspaceFolder,
  repositoryUrl: string,
  repositoryToken: string,
  _,
  repositoryUsername?: string
) => {
  const folderName = serializeId(folderId);
  if (!folderName) throw new Error("Folder does not exist");
  const fileDir = getDirPath(workspace, folder);
  const dir = getWorkspaceLocation(workspace.name);
  const fs = getWorkspaceFS();
  const folderPath = posixPath.relative(
    dir,
    posixPath.join(fileDir, folderName)
  );

  const removedFiles = (
    await git.statusMatrix({
      fs,
      dir,
      filepaths: [folderPath],
    })
  )
    .filter((row) => row[IDX.WORKDIR] === STATUS.ABSENT)
    .map((row) => row[IDX.FILE]);

  if (removedFiles.length === 0) {
    return;
  }

  const gitActions = removedFiles.map((filepath) =>
    git.remove({
      fs,
      dir,
      filepath,
    })
  );

  await Promise.all(gitActions);
  await git.commit({
    fs,
    dir,
    author: AUTHOR,
    message: `Removed '${folderName}' folder`,
  });

  await gitPush(
    repositoryToken,
    {
      dir,
      url: repositoryUrl,
      ref: workspace.name,
    },
    repositoryUsername
  );
};

export type PushMovedFolderToGit = (
  workspace: Workspace,
  folder: WorkspaceFolder,
  oldPath: string,
  newPath: string,
  repositoryUrl: string,
  repositoryToken: string,
  repositoryUsername?: string
) => Promise<void>;

export const pushModifiedFolderToGit: PushMovedFolderToGit = async (
  workspace,
  folder,
  oldPath,
  newPath,
  repositoryUrl,
  repositoryToken,
  repositoryUsername
) => {
  const fs = getWorkspaceFS();
  const fileDir = getDirPath(workspace, folder);
  const dir = getWorkspaceLocation(workspace.name);
  const entityFolderPath = posixPath.relative(dir, fileDir);

  const status = await git.statusMatrix({
    fs,
    dir,
    filepaths: [entityFolderPath],
    filter: (f) => f.includes(newPath) || f.includes(oldPath),
  });

  if (status.length === 0) {
    return;
  }

  await Promise.allSettled(
    status.map((stat) => addFileByMatrix(stat, { fs, dir }))
  );

  const verb =
    posixPath.dirname(oldPath) === posixPath.dirname(newPath)
      ? "Renamed"
      : "Moved";
  await git.commit({
    fs,
    dir,
    author: AUTHOR,
    message: `${verb} folder '${oldPath}' to '${newPath}'`,
  });

  await gitPush(
    repositoryToken,
    {
      dir,
      url: repositoryUrl,
      ref: workspace.name,
    },
    repositoryUsername
  );
};

/**
 * includes saving a file, adding to git, committing and pushing
 */
export const pushChangesToGit: PushChangesToGit = async (
  fileId,
  workspace,
  folder,
  repositoryUrl,
  repositoryToken,
  oldPath,
  repositoryUsername
) => {
  const fileName = serializeId(fileId);
  if (!fileName) throw new Error("File does not exist");

  const fileDir = getDirPath(workspace, folder);
  const dir = getWorkspaceLocation(workspace.name);
  const fs = getWorkspaceFS();
  const filepath = posixPath.relative(dir, posixPath.join(fileDir, fileName));

  const status = await git.status({
    fs,
    dir,
    filepath,
  });

  if (status === "unmodified") {
    return;
  }

  await addFile(status, dir, filepath);

  const isRenamed = oldPath !== undefined;
  const isMoved =
    isRenamed && posixPath.dirname(fileName) !== posixPath.dirname(oldPath!);

  if (isRenamed || isMoved) {
    const oldFilepath = posixPath.relative(
      dir,
      posixPath.join(fileDir, oldPath!)
    );
    await git.remove({
      fs,
      dir,
      filepath: oldFilepath,
    });
  }

  const actionVerb = getCommittedActionVerb(status, isRenamed, isMoved);
  await git.commit({
    fs,
    dir,
    author: AUTHOR,
    message: `${actionVerb} the '${
      isRenamed || isMoved ? oldPath : fileName
    }' file`,
  });

  await gitPush(
    repositoryToken,
    {
      dir,
      url: repositoryUrl,
      ref: workspace.name,
    },
    repositoryUsername
  );
};

export async function resetToRemote(
  workspaceName: string,
  repositoryUrl: string,
  repositoryToken: string,
  repositoryUsername?: string
): Promise<void> {
  const workspaceLocation = getWorkspaceLocation(workspaceName);
  await fetchWorkspaceBranch(
    workspaceName,
    workspaceLocation,
    repositoryUrl,
    repositoryToken,
    repositoryUsername
  );
  await gitResetHard(
    workspaceName,
    await getLatestRemoteCommitOid(workspaceName, workspaceLocation),
    workspaceLocation
  );
}

export async function resetToLocal(
  workspaceName: string,
  repositoryUrl: string,
  repositoryToken: string,
  repositoryUsername?: string
): Promise<void> {
  await gitPush(
    repositoryToken,
    {
      dir: getWorkspaceLocation(workspaceName),
      ref: workspaceName,
      url: repositoryUrl,
      force: true,
    },
    repositoryUsername
  );
}

export async function moveChangesToNewBranch(
  newBranchName: string,
  workspaceName: string,
  repositoryUrl: string,
  repositoryToken: string,
  repositoryUsername?: string
): Promise<void> {
  const fs = getWorkspaceFS();
  const workspaceLocation = getWorkspaceLocation(workspaceName);

  const branchExists = await getBranchExists(
    newBranchName,
    workspaceLocation,
    repositoryUrl,
    repositoryToken,
    repositoryUsername
  );

  if (branchExists) throw new Error(GIT_ERROR_BRANCH_ALREADY_EXISTS);

  await gitBranch({
    dir: workspaceLocation,
    ref: newBranchName,
  });

  await gitResetHard(
    workspaceName,
    await getLatestRemoteCommitOid(workspaceName, workspaceLocation),
    workspaceLocation
  );

  await git.checkout({
    fs,
    dir: workspaceLocation,
    ref: newBranchName,
  });

  await gitPush(
    repositoryToken,
    {
      dir: getWorkspaceLocation(workspaceName),
      ref: newBranchName,
      url: repositoryUrl,
    },
    repositoryUsername
  );
}

/**
 * Verify that local branch is not ahead of the remote one
 * @param workspaceName
 * @param workspaceLocation
 */
async function verifyGitHistory(
  workspaceName: string,
  workspaceLocation: string
) {
  const remoteLatestCommit = await getLatestRemoteCommitOid(
    workspaceName,
    workspaceLocation
  );
  const localLatestCommit = await getLatestLocalCommitOid(
    workspaceName,
    workspaceLocation
  );

  const isLocalBranchAhead = await getIsDescendent(
    remoteLatestCommit,
    localLatestCommit,
    workspaceLocation
  );

  if (isLocalBranchAhead) {
    console.debug(
      `remoteLatestCommit=${remoteLatestCommit} localLatestCommit=${localLatestCommit}`
    );
    throw new Error(GIT_ERROR_BRANCH_HAS_DIVERGED);
  }
}

/**
 * Is branch published to remote
 * @param workspaceName
 * @param workspaceLocation
 */
async function getIsBranchPublished(
  workspaceName: string,
  workspaceLocation: string
) {
  try {
    console.debug("getIsBranchPublished");
    await getLatestRemoteCommitOid(workspaceName, workspaceLocation);
    return true;
  } catch (e) {
    return false;
  }
}

export async function synchronizeWorkspaceWithRemote(
  workspaceName: string,
  workspaceLocation: string,
  repositoryUrl: string,
  repositoryToken: string,
  repositoryUsername?: string
): Promise<void> {
  const isBranchPublished = await getIsBranchPublished(
    workspaceName,
    workspaceLocation
  );

  if (!isBranchPublished) {
    throw new Error(`Workspace '${workspaceName}' does not exist on server`);
  }

  const isLocalUpToDate = await getIsLocalBranchUpToDate(
    workspaceName,
    workspaceLocation,
    repositoryUrl,
    repositoryToken,
    repositoryUsername
  );

  if (isLocalUpToDate) {
    return;
  }

  await verifyGitHistory(workspaceName, workspaceLocation);

  await gitFastForward(
    repositoryToken,
    {
      ref: workspaceName,
      url: repositoryUrl,
      dir: workspaceLocation,
    },
    repositoryUsername
  );
}

async function addFile(
  status: Awaited<ReturnType<typeof git.status>>,
  dir: string,
  filepath: string
) {
  const fs = getWorkspaceFS();

  if (status === "*deleted") {
    await git.remove({
      fs,
      dir,
      filepath,
    });
  } else {
    await git.add({
      fs,
      dir,
      filepath,
    });
  }
}

async function resetWorkingDirToBranch(
  workspaceLocation: string,
  branch: string
) {
  const fs = getWorkspaceFS();
  const gitIndexLocation = posixPath.join(workspaceLocation, ".git/index");
  // clear all staged files
  await fs.promises.unlink(gitIndexLocation);
  // update working directory with the files from remote
  await git.checkout({
    dir: workspaceLocation,
    fs,
    ref: branch,
    force: true,
  });
}

async function updateHeadPointer(
  workspaceLocation: string,
  branch: string,
  commitOid: string
) {
  const fs = getWorkspaceFS();
  const branchHeadPath = posixPath.join(
    workspaceLocation,
    `.git/refs/heads/${branch}`
  );

  await fs.promises.writeFile(branchHeadPath, commitOid);
}

/**
 * Important: make sure to checkout to the {branch} before resetting it
 * @param branch
 * @param commitOid
 * @param workspaceLocation
 */
const gitResetHard = async (
  branch: string,
  commitOid: string,
  workspaceLocation: string
) => {
  await updateHeadPointer(workspaceLocation, branch, commitOid);
  await resetWorkingDirToBranch(workspaceLocation, branch);
  await deleteUntrackedFiles(workspaceLocation);
};

async function deleteUntrackedFiles(workspaceLocation: string) {
  const fs = getWorkspaceFS();
  const untrackedFileNames = (
    await git.statusMatrix({ fs, dir: workspaceLocation })
  )
    .filter(
      (row) =>
        row[IDX.HEAD] === STATUS.ABSENT &&
        row[IDX.WORKDIR] === STATUS.SAME_AS_WORKDIR &&
        row[IDX.STAGE] === STATUS.ABSENT
    )
    .map((row) => row[IDX.FILE]);

  const deleteFilePromises = untrackedFileNames.map((filename: string) =>
    fs.promises.unlink(posixPath.join(workspaceLocation, filename))
  );

  try {
    await Promise.all(deleteFilePromises);
  } catch (e) {
    console.error("Can not delete untracked files", e);
  }
}

async function getBranchExists(
  branchOrTagName: string,
  workspaceLocation: string,
  repositoryUrl: string,
  repositoryToken: string,
  repositoryUsername?: string
) {
  const fs = getWorkspaceFS();
  // update list of remote branches
  await gitFetch(
    repositoryToken,
    {
      url: repositoryUrl,
      dir: workspaceLocation,
      singleBranch: false,
    },
    repositoryUsername
  );
  const remoteBranches = await git.listBranches({
    fs,
    dir: workspaceLocation,
    remote: "origin",
  });
  return remoteBranches.includes(branchOrTagName);
}

async function getIsDescendent(
  ancestorOid: string,
  commitOid: string,
  workspaceLocation: string
) {
  const fs = getWorkspaceFS();

  try {
    console.debug("getIsDescendent");
    return await git.isDescendent({
      fs,
      dir: workspaceLocation,
      ancestor: ancestorOid,
      oid: commitOid,
    });
  } catch (e) {
    console.error(e);
    return false;
  }
}

async function getLatestRemoteCommitOid(
  workspaceName: string,
  workspaceLocation: string
): Promise<string> {
  return await getLatestLocalCommitOid(
    `origin/${workspaceName}`,
    workspaceLocation
  );
}

export async function getLatestLocalCommitOid(
  workspaceName: string,
  workspaceLocation: string
): Promise<string> {
  console.debug(`getLatestLocalCommitOid: ${workspaceName}`);

  return await git.resolveRef({
    fs: getWorkspaceFS(),
    dir: workspaceLocation,
    ref: workspaceName,
  });
}

// TODO: replace with native Awaited type of TS 4.5. https://www.typescriptlang.org/docs/handbook/release-notes/typescript-4-5.html#the-awaited-type-and-promise-improvements
type Awaited<T> = T extends Promise<infer U> ? U : T;

function getCommittedActionVerb(
  status: Awaited<ReturnType<typeof git.status>>,
  isRenamed = false,
  isMoved = false
): string {
  if (isMoved) return "Moved";
  if (isRenamed) return "Renamed";

  switch (status) {
    case "*deleted":
      return "Deleted";
    case "*modified":
      return "Updated";
    case "*added":
    default:
      return "Added";
  }
}

async function fetchWorkspaceBranch(
  workspaceName: string,
  workspaceLocation: string,
  repositoryUrl: string,
  repositoryToken: string,
  repositoryUsername?: string
): Promise<FetchResult> {
  return await gitFetch(
    repositoryToken,
    {
      ref: workspaceName,
      url: repositoryUrl,
      dir: workspaceLocation,
    },
    repositoryUsername
  );
}

export async function getIsLocalBranchUpToDate(
  workspaceName: string,
  workspaceLocation: string,
  repositoryUrl: string,
  repositoryToken: string,
  repositoryUsername?: string
): Promise<boolean> {
  await fetchWorkspaceBranch(
    workspaceName,
    workspaceLocation,
    repositoryUrl,
    repositoryToken,
    repositoryUsername
  );

  const remoteLatestCommit = await getLatestRemoteCommitOid(
    workspaceName,
    workspaceLocation
  );
  const localLatestCommit = await getLatestLocalCommitOid(
    workspaceName,
    workspaceLocation
  );

  return remoteLatestCommit === localLatestCommit;
}

export async function initializeGitRepository({
  repositoryToken,
  workspaceName,
  repositoryUrl,
  workspaceLocation,
  repositoryDefaultBranch,
  repositoryUsername,
}: {
  repositoryDefaultBranch?: string;
  repositoryToken?: string;
  repositoryUrl?: string;
  repositoryUsername?: string;
  workspaceLocation: string;
  workspaceName: string;
}): Promise<void> {
  if (repositoryUrl === undefined || repositoryToken === undefined) {
    await gitInit({
      dir: workspaceLocation,
      defaultBranch: workspaceName,
    });
    return;
  }

  await gitClone(
    repositoryToken,
    {
      dir: workspaceLocation,
      url: repositoryUrl,
      ref: repositoryDefaultBranch,
    },
    repositoryUsername
  );

  if (repositoryDefaultBranch !== workspaceName) {
    await gitBranch({
      dir: workspaceLocation,
      ref: workspaceName,
      checkout: true,
    });

    await gitPush(
      repositoryToken,
      {
        dir: workspaceLocation,
        url: repositoryUrl,
        ref: workspaceName,
      },
      repositoryUsername
    );
  }
}

export async function getRemoteInfo(
  repositoryUrl: string,
  token: string,
  username?: string
): Promise<GetRemoteInfoResult> {
  try {
    console.debug("getRemoteInfo");
    return await git.getRemoteInfo({
      http: getWorkspaceHttpClient(),
      url: repositoryUrl,
      ...createGitCredentialsForRepo(repositoryUrl, token, username),
    });
  } catch (e) {
    assertNoExpiredAccessToken(e);
    throw e;
  }
}

async function gitCloneBase(
  args: Omit<GitCloneOptions, "fs" | "http">
): Promise<void> {
  console.debug("gitCloneBase");
  await git.clone({
    fs: getWorkspaceFS(),
    http: getWorkspaceHttpClient(),
    singleBranch: true,
    ...args,
  });
}

type GitCloneOptions = Parameters<typeof git.clone>[0];

export async function gitClone(
  repositoryToken: string,
  args: Omit<GitCloneOptions, "fs" | "http" | "headers">,
  username?: string
): Promise<void> {
  try {
    await gitCloneBase({
      ...createGitCredentialsForRepo(args.url, repositoryToken, username),
      ...args,
      noTags: true,
    });
  } catch (e) {
    console.error(e);
    assertNoExpiredAccessToken(e);
    throw e;
  }
}

type GitFetchOptions = Parameters<typeof git.clone>[0];

export async function gitFetch(
  repositoryToken: string,
  args: Omit<GitFetchOptions, "fs" | "http" | "headers">,
  username?: string
): Promise<FetchResult> {
  try {
    console.debug("gitFetch");
    return await git.fetch({
      fs: getWorkspaceFS(),
      http: getWorkspaceHttpClient(),
      ...createGitCredentialsForRepo(args.url, repositoryToken, username),
      singleBranch: true,
      ...args,
    });
  } catch (e) {
    console.error(e);
    assertNoExpiredAccessToken(e);
    throw e;
  }
}

type GitPushOptions = Parameters<typeof git.push>[0];

export async function gitPush(
  repositoryToken: string,
  args: Omit<GitPushOptions, "fs" | "http" | "headers">,
  username?: string
): Promise<PushResult> {
  try {
    console.debug("gitPush");
    return await git.push({
      fs: getWorkspaceFS(),
      http: getWorkspaceHttpClient(),
      ...createGitCredentialsForRepo(
        args.url as string,
        repositoryToken,
        username
      ),
      ...args,
    });
  } catch (e) {
    console.error(e);
    assertNoBranchDivergence(e);
    assertNoExpiredAccessToken(e);
    throw e;
  }
}

type GitBranchOptions = Parameters<typeof git.branch>[0];

export function gitBranch(args: Omit<GitBranchOptions, "fs">): Promise<void> {
  console.debug("gitBranch");
  return git.branch({
    fs: getWorkspaceFS(),
    ...args,
  });
}

type GitInit = Parameters<typeof git.init>[0];

export function gitInit(args: Omit<GitInit, "fs">): Promise<void> {
  console.debug("gitInit");
  return git.init({
    fs: getWorkspaceFS(),
    ...args,
  });
}

type GitFastForwardOptions = Parameters<typeof git.fastForward>[0];

export async function gitFastForward(
  repositoryToken: string,
  args: Omit<GitFastForwardOptions, "fs" | "http" | "headers">,
  username?: string
): Promise<void> {
  try {
    console.debug("gitFastForward");
    return await git.fastForward({
      fs: getWorkspaceFS(),
      http: getWorkspaceHttpClient(),
      ...createGitCredentialsForRepo(
        args.url as string,
        repositoryToken,
        username
      ),
      singleBranch: true,
      ...args,
    });
  } catch (e) {
    console.error(e);
    assertNoBranchDivergence(e);
    assertNoExpiredAccessToken(e);
    throw e;
  }
}
