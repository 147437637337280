import { FC, useRef } from "react";
import { useEffect } from "react";
import { Cancel } from "@mui/icons-material";
import { IconButton, styled } from "@mui/material";
import cloneDeep from "lodash/cloneDeep";
import {
  SnackbarAction,
  SnackbarKey,
  SnackbarProvider,
  TransitionCloseHandler,
  useSnackbar,
} from "notistack";

import { Notification } from "../model/notification";
import { useAppDispatch, useAppSelector } from "../store";
import { removeNotification } from "../store/designer/reducers";

const NotificationHandler: React.ElementType = ({ children }) => {
  const notifications = useAppSelector((state) => state.designer.notifications);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const active = useRef([] as Notification[]);

  useEffect(
    () => () => {
      active.current.forEach((notification) => {
        closeSnackbar(notification.key);
      });
      active.current = [];
    },
    [closeSnackbar]
  );

  useEffect(() => {
    const previous = cloneDeep(active.current);
    const current = cloneDeep(notifications);

    const added = current.filter((n) => !previous.find((p) => p.key === n.key));
    const removed = previous.filter(
      (n) => !current.find((p) => p.key === n.key)
    );

    const onClose: TransitionCloseHandler = (_e, reason, key) => {
      dispatch(removeNotification(`${key}`));
    };

    added.forEach((n) =>
      enqueueSnackbar(n.message, { key: n.key, variant: n.variant, onClose })
    );
    removed.forEach((n) => closeSnackbar(n.key));

    active.current = notifications;
  }, [notifications, closeSnackbar, enqueueSnackbar, dispatch]);

  return children;
};

const CloseSnackbar: SnackbarAction = (key) => {
  const dispatch = useAppDispatch();

  function handleCancel(key: SnackbarKey) {
    dispatch(removeNotification(`${key}`));
  }

  return (
    <IconButton
      sx={{ color: "white" }}
      onClick={() => handleCancel(key)}
      data-testid="close-notification"
    >
      <Cancel />
    </IconButton>
  );
};

const StyledSnackbarProvider = styled(SnackbarProvider)(({ theme }) => ({
  "&.SnackbarItem-variantSuccess": {
    backgroundColor: theme.palette.success.main,
  },
  "&.SnackbarItem-variantInfo": { backgroundColor: theme.palette.info.main },
  "&.SnackbarItem-variantWarning": {
    backgroundColor: theme.palette.warning.main,
  },
  "&.SnackbarItem-variantError": { backgroundColor: theme.palette.danger },
}));

const NotificationProvider: FC = ({ children }) => (
  <StyledSnackbarProvider
    action={CloseSnackbar}
    maxSnack={4}
    anchorOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    ClickAwayListenerProps={{ mouseEvent: false, touchEvent: false }}
    data-testid="notification"
  >
    <NotificationHandler>{children}</NotificationHandler>
  </StyledSnackbarProvider>
);

export default NotificationProvider;
