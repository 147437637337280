import { FC, SetStateAction, useCallback, useState } from "react";
import { useEffect } from "react";
import CachedIcon from "@mui/icons-material/Cached";
import { Button, Container, Stack } from "@mui/material";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import useDebounce from "@sapiens-digital/ace-designer-app/app/components/utils/useDebounce";
import { useIsLoading } from "@sapiens-digital/ace-designer-app/app/components/utils/useIsLoading";

import { AvailableAPIsList } from "./AvailableAPIsList";

export type ApiSpecEntity = {
  name: string;
};

export const trimUrl = (baseUrl: string): string =>
  baseUrl.replace(/^((\w+:)?\/\/[^/]+\/?).*$/, "$1");

export const ERROR_NO_URL = "Please enter API spec URL";
export const ERROR_FAILED_TO_LOAD = "Couldn't load APIs list";

const getAPIsList = async (
  baseUrl: string,
  setLoadError: React.Dispatch<SetStateAction<string>>
) => {
  if (!baseUrl) {
    setLoadError(ERROR_NO_URL);
    return;
  }

  try {
    const response = await fetch(baseUrl);
    if (!response.ok) {
      throw new Error(response.statusText);
    }

    const data = await response.json();
    if (!Array.isArray(data)) {
      return;
    }

    return data as ApiSpecEntity[];
  } catch (err) {
    setLoadError(ERROR_FAILED_TO_LOAD);
  }
};

const constructBaseUrl = () => {
  const apiBaseUrl = process.env.REACT_APP_DEV_API_BASE_URL;

  if (apiBaseUrl) {
    return `${trimUrl(apiBaseUrl)}api-spec`;
  }

  return "";
};

const APIsList: FC = () => {
  const [apis, setApis] = useState<ApiSpecEntity[]>([]);
  const [baseUrl, setBaseUrl] = useState(constructBaseUrl());
  const [loadError, setLoadError] = useState("");
  const baseUrlDebounced = useDebounce(baseUrl, 500);

  const fetchAPIsData = useCallback(async () => {
    setLoadError("");
    const data = await getAPIsList(baseUrlDebounced, setLoadError);
    if (data) {
      setApis(data);
    } else {
      setApis([]);
    }
  }, [baseUrlDebounced]);

  const [isLoading, loadFetchAPIsData] = useIsLoading(fetchAPIsData);

  useEffect(() => {
    loadFetchAPIsData();
  }, [loadFetchAPIsData]);

  return (
    <Container
      maxWidth="sm"
      sx={{
        marginTop: "2rem",
        backgroundColor: "#FFF",
      }}
    >
      <Stack direction="row" sx={{ paddingTop: "1rem" }} spacing={0.5}>
        <Box component="form" noValidate sx={{ flex: 1 }}>
          <TextField
            id="base-url"
            label="APi spec URL"
            variant="outlined"
            value={baseUrl}
            onChange={(event) => setBaseUrl(event.target.value)}
            fullWidth
          />
        </Box>
        <Button aria-label="reload" variant="outlined">
          <CachedIcon
            data-testid="reload-button"
            fontSize="large"
            onClick={() => fetchAPIsData()}
          />
        </Button>
      </Stack>
      <AvailableAPIsList
        isLoading={isLoading}
        apis={apis}
        specUrl={baseUrlDebounced}
        loadError={loadError}
      />
    </Container>
  );
};

export { APIsList };
