export const WORKSPACE_FLOWS = "flows";
export const WORKSPACE_VIRTUAL_STEPS = "virtual-steps";
export const WORKSPACE_APIS = "apis";
export const WORKSPACE_SCHEDULES = "schedules";
export const WORKSPACE_ERROR_HANDLERS = "error-handlers";
export const WORKSPACE_VARIABLES = "variables";
export const WORKSPACE_SCHEMAS = "schemas";
export const WORKSPACE_API_SCHEMAS = `${WORKSPACE_APIS}/${WORKSPACE_SCHEMAS}`;
export const WORKSPACE_API_FIELDS = `${WORKSPACE_APIS}/fields`;
export const WORKSPACE_API_HEADERS = `${WORKSPACE_APIS}/headers`;
export const WORKSPACE_API_PARAMETERS = `${WORKSPACE_APIS}/parameters`;
export const WORKSPACE_API_RESPONSES = `${WORKSPACE_APIS}/responses`;
