import { REGX_FOR_VALID_STRING_EXPRESSION, Steps, StepSchema } from "../model";

export const catchStepSchema: StepSchema = {
  stepType: Steps.CATCH,
  color: "rgb(64, 255, 0)",
  jsonSchema: {
    title: "Catch",
    description: "Catch errors in previous step block executions.",
    type: "object",
    properties: {
      name: {
        type: "string",
        title: "Name",
      },
      stepType: {
        type: "string",
        title: "stepType",
      },
      description: {
        type: "string",
        title: "Step Description",
      },
      condition: {
        type: "string",
        title:
          "An optional condition on the step. If returns false step will be skipped (e.g {{input.age>10}})",
        pattern: REGX_FOR_VALID_STRING_EXPRESSION,
      },
      config: {
        type: "object",
        title: "Configuration",
        properties: {
          stopExecution: {
            type: "boolean",
            title:
              "If checked, this will stop the further execution of the flow.",
            default: false,
          },
          errorPath: {
            type: "string",
            title:
              "Errors object path - key in which to store the errors object (errors by default)",
          },
          mixedFlowConfig: {
            type: "object",
            title: "Execute a mixed flow.",
            required: ["flowId"],
            properties: {
              flowId: {
                type: "string",
                title: "Flow name",
              },
              payload: {
                type: "string",
                title: "Flow payload path (doc - by default)",
              },
              targetPath: {
                type: "string",
                title:
                  "Target path - define the path in which to store the flow result (original flow target if not defined)",
              },
            },
          },
        },
      },
    },
  },
  uiSchema: {
    stepType: {
      "ui:widget": "hidden",
    },
    name: {
      classNames: "hidden-ace4",
    },
    description: {
      "ui:widget": "textarea",
    },
    config: {
      mixedFlowConfig: {
        flowId: {
          "ui:field": "flowSearch",
        },
      },
    },
  },
};
