import posixPath from "@sapiens-digital/ace-designer-common/lib/helpers/posixPath";
import internal from "stream";
import yauzl from "yauzl-promise";

import { writeFileDeep } from "./fs-utils";

const DEFAULT_UNZIP_TIMEOUT_MS = 300000;

export const ERR_MSG_CANNOT_READ_FILE =
  "Could not read selected file. Please verify it is a valid zip file and not corrupted.";
export const ERR_MSG_TIMEOUT = "Extraction of the zip file timed out.";

export async function extractZipToFolder(
  targetLocation: string,
  buffer: Buffer,
  msTimeout?: number
): Promise<void> {
  await timeoutPromise(
    extractBuffer(buffer, async (fileName: string, fileBuffer: Buffer) => {
      await writeFileDeep(posixPath.join(targetLocation, fileName), fileBuffer);
    }),
    msTimeout || DEFAULT_UNZIP_TIMEOUT_MS
  );
}

async function extractBuffer(
  buffer: Buffer,
  onFile: (fileName: string, buffer: Buffer) => Promise<void>
): Promise<void> {
  let zipfile: yauzl.ZipFile;

  try {
    zipfile = await yauzl.fromBuffer(buffer, {
      lazyEntries: true,
      autoClose: false,
    });
  } catch (e) {
    throw ERR_MSG_CANNOT_READ_FILE;
  }

  try {
    for (
      let entry = await zipfile.readEntry();
      entry;
      entry = await zipfile.readEntry()
    ) {
      if (isDirectory(entry.fileName)) {
        continue;
      }

      const readStream = await entry.openReadStream();
      const contentBuffer = await streamToBuffer(readStream);

      await onFile(entry.fileName, contentBuffer);
    }
  } finally {
    zipfile.close();
  }
}

async function streamToBuffer(stream: internal.Readable): Promise<Buffer> {
  const chunks: Uint8Array[] = [];
  return new Promise((resolve, reject) => {
    stream.on("data", (chunk) => chunks.push(chunk));
    stream.on("error", reject);
    stream.on("end", () => resolve(Buffer.concat(chunks)));
  });
}

function isDirectory(somePath: string): boolean {
  return somePath.endsWith("/") || somePath.endsWith("\\");
}

async function timeoutPromise<T>(
  promise: Promise<T>,
  msTimeout: number
): Promise<T | undefined> {
  const timeoutPromise: Promise<undefined> = new Promise((_, reject) => {
    setTimeout(() => {
      reject(ERR_MSG_TIMEOUT);
    }, msTimeout);
  });

  return await Promise.race([promise, timeoutPromise]);
}
