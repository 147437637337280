import { createAsyncThunk } from "@reduxjs/toolkit";

import { ExtendedSettings, WorkspaceVersion } from "../../model/workspace";
import { getWorkspaceLocation, loadWorkspace } from "../../services/workspace";

export const loadWorkspaceAction = createAsyncThunk(
  "workspaces/loadWorkspace",
  async (parameters: ExtendedSettings) => {
    const workspaceLocation = getWorkspaceLocation(parameters.workspaceName);
    return await loadWorkspace(
      workspaceLocation,
      parameters.repositoryRoot,
      WorkspaceVersion.V2,
      parameters.workspaceName
    );
  }
);
