import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import DownloadIcon from "@mui/icons-material/Download";
import RefreshIcon from "@mui/icons-material/Refresh";
import UploadIcon from "@mui/icons-material/Upload";
import { Button } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { styled } from "@mui/material/styles";

import { SettingsManager } from "../../services/settingsManager";
import { downloadWorkspaceAsZip } from "../../services/workspace/workspace";
import { useAppSelector } from "../../store";
import { openWorkspaceManager } from "../../store/designer/reducers";
import { selectDefaultBranchName } from "../../store/workspaces/selectors";
import WorkspaceIcon from ".././icons/Workspace";
import { useWorkspaceRefresh } from "../utils/useWorkspaceRefresh";

import { ImportWorkspaceDialog } from "./ImportWorkspaceDialog";

const Root = styled("div")(() => ({
  display: "flex",
  height: "20px",
  marginLeft: "110px",
}));

const bottomBarButtonStyles = {
  fontSize: "11px",
  marginLeft: "4px",
  padding: "0 !important",
  color: "white",
  minWidth: "42px !important",
};
const createBranchPlaceholder = "<unknown>";

const WorkspaceSelector: React.FC = () => {
  const [workspaceImportOpen, setWorkspaceImportOpen] = React.useState(false);
  const [isExporting, setIsExporting] = React.useState(false);
  const name = useAppSelector(selectDefaultBranchName);

  const dispatch = useDispatch();

  const { handleRefresh } = useWorkspaceRefresh();

  const handleImportOpen = () => {
    setWorkspaceImportOpen(true);
  };

  const handleImportClose = () => {
    setWorkspaceImportOpen(false);
  };

  const handleExport = useCallback(async () => {
    if (name) {
      setIsExporting(true);
      await downloadWorkspaceAsZip(
        SettingsManager.getWorkspacesLocation(),
        name,
        SettingsManager.getDesignerSettings().repositoryRoot
      );
      setIsExporting(false);
    }
  }, [name]);

  return (
    <Root>
      <Button
        onClick={(e) => {
          e.currentTarget.blur();
          dispatch(openWorkspaceManager());
        }}
        sx={{
          fontSize: "11px",
          marginLeft: "4px",
          padding: 0,
          color: "white",
        }}
        data-testid="workspaceSelector"
        startIcon={
          <WorkspaceIcon sx={{ height: "16px", width: "16px", margin: "0" }} />
        }
      >
        {name === undefined ? createBranchPlaceholder : name}
      </Button>
      <Button
        onClick={handleRefresh}
        sx={bottomBarButtonStyles}
        data-testid="refresh-workspace-btn"
        startIcon={
          <RefreshIcon
            sx={{ height: "16px", width: "16px", margin: "0 -3px 0 24px" }}
          />
        }
      >
        Refresh
      </Button>
      <Button
        onClick={handleImportOpen}
        sx={bottomBarButtonStyles}
        data-testid="open-workspace"
        startIcon={
          <UploadIcon
            sx={{ height: "16px", width: "16px", margin: "0 -3px 0 24px" }}
          />
        }
      >
        Import
      </Button>
      {isExporting ? (
        <CircularProgress
          sx={{ color: "white", marginTop: "3px", marginLeft: "24px" }}
          size={12}
          data-testid="ws-exporting-loading"
        />
      ) : (
        <Button
          onClick={handleExport}
          sx={bottomBarButtonStyles}
          data-testid="export-workspace-btn"
          startIcon={
            <DownloadIcon
              sx={{ height: "16px", width: "16px", margin: "0 -3px 0 24px" }}
            />
          }
        >
          Export
        </Button>
      )}
      {workspaceImportOpen && (
        <ImportWorkspaceDialog
          open={workspaceImportOpen}
          handleClose={handleImportClose}
        />
      )}
    </Root>
  );
};

export default WorkspaceSelector;
