import {
  createSlice,
  isAnyOf,
  isFulfilled,
  isPending,
  isRejected,
  PayloadAction,
} from "@reduxjs/toolkit";
import { v4 } from "uuid";

import { Designer, TabBaseUiConfig } from "../../model/designer";
import { FieldError } from "../../model/formError";
import { NotificationOptionalKey } from "../../model/notification";
import {
  generateDataForInputSchemaAction,
  testFlowAction,
  testStepAction,
} from "../flows/actions";
import { testSchedulerAction } from "../schedules/actions";
import { initializeVariablesAction } from "../variables/initializeVariablesAction";
import { indexWorkspaceReferencesAction } from "../workspaces/actions";
import {
  moveWorkspaceToAnotherBranchAction,
  resetRemoteToWorkspaceAction,
  resetWorkspaceToRemoteAction,
} from "../workspaces/conflictResolutionActions";

import {
  addNotification,
  getACEVersionAction,
  hideLocalRepositoryWarning,
  selectApiTreeEntity,
  setWorkspaceHasConflictsFlagAction,
  showLocalRepositoryWarning,
} from "./actions";
import { ALL_NODES_ID, ROOT_NODE_ID } from "./constants";
import {
  resetAllOpenFlowsData,
  setUiData,
  toggleEntityFolder,
} from "./mutator";

export const initialState: Designer = {
  isDesignerLoading: true,
  settingsOpen: false,
  workspaceManagerOpen: false,
  version: "",
  showSideNav: true,
  selectedStepIds: {},
  selectedFlowsFolderId: ALL_NODES_ID,
  selectedSchemasFolderId: ALL_NODES_ID,
  selectedVirtualStepsFolderId: ALL_NODES_ID,
  selectedApiTreeEntityId: ROOT_NODE_ID,
  expandedSchemasFolderIds: [ROOT_NODE_ID],
  expandedVirtualStepsFolderIds: [ROOT_NODE_ID],
  expandedFlowsFolderIds: [ROOT_NODE_ID],
  expandedApiFolderIds: [ROOT_NODE_ID],
  notifications: [],
  selectedPreviewFlowId: "",
  hasWorkspaceConflicts: false,
  localRepositoryWarning: false,
  entityValidationErrors: {},
  selectedVariablesId: "",
  selectedSchemaId: "",
  selectedVirtualStepId: "",
  flowTestResult: "",
  flowsSourceViewEnabled: {},
  stepUi: {},
  flowUi: {},
  schedulerUi: {},
  isIndexing: false,
};

const uiTabLoaderInitState = {
  loading: false,
};

const isWorkspaceConflictResolutionFulfilled = isFulfilled(
  resetWorkspaceToRemoteAction,
  resetRemoteToWorkspaceAction,
  moveWorkspaceToAnotherBranchAction
);

const slice = createSlice({
  name: "designer",
  initialState: initialState,
  reducers: {
    openSettings: (state) => {
      state.settingsOpen = true;
    },
    closeSettings: (state) => {
      state.settingsOpen = false;
    },
    openWorkspaceManager: (state) => {
      state.workspaceManagerOpen = true;
    },
    closeWorkspaceManager: (state) => {
      state.workspaceManagerOpen = false;
    },
    setDesignerLoading: (state, action: PayloadAction<boolean>) => {
      state.isDesignerLoading = action.payload;
    },
    selectFlowFolder: (state, action: PayloadAction<string>) => {
      state.selectedFlowsFolderId = action.payload;
    },
    selectVariables(state, action: PayloadAction<string>) {
      state.selectedVariablesId = action.payload;
    },
    selectSchemaFolder: (state, action: PayloadAction<string>) => {
      state.selectedSchemasFolderId = action.payload;
    },
    selectVirtualStepsFolder: (state, action: PayloadAction<string>) => {
      state.selectedVirtualStepsFolderId = action.payload;
    },
    toggleFlowFolder: (state, action: PayloadAction<string>) => {
      toggleEntityFolder(state, action.payload, "expandedFlowsFolderIds");
    },
    toggleSchemasFolder: (state, action: PayloadAction<string>) => {
      toggleEntityFolder(state, action.payload, "expandedSchemasFolderIds");
    },
    toggleVirtualStepsFolder: (state, action: PayloadAction<string>) => {
      toggleEntityFolder(
        state,
        action.payload,
        "expandedVirtualStepsFolderIds"
      );
    },
    toggleApiFolder: (state, action: PayloadAction<string>) => {
      toggleEntityFolder(state, action.payload, "expandedApiFolderIds");
    },
    selectStep(
      state,
      action: PayloadAction<{ flowId: string; stepId: string }>
    ) {
      const { stepId, flowId } = action.payload;
      state.selectedStepIds[flowId] = stepId;
    },
    deselectStep(state, action: PayloadAction<string>) {
      delete state.selectedStepIds[action.payload];
    },
    selectSchema(state, action: PayloadAction<string>) {
      state.selectedSchemaId = action.payload;
    },
    deselectSchema(state) {
      state.selectedSchemaId = "";
    },
    selectVirtualStep(state, action: PayloadAction<string>) {
      state.selectedVirtualStepId = action.payload;
    },
    deselectVirtualStep(state) {
      state.selectedVirtualStepId = "";
    },
    removeFlowDebugData(state, action: PayloadAction<string>) {
      delete state.flowUi[action.payload];
    },
    removeStepDebugData(state, action: PayloadAction<string>) {
      for (const key in state.stepUi) {
        if (state.stepUi[key].flowId === action.payload) {
          delete state.stepUi[key];
        }
      }
    },
    removeNotification(state, action: PayloadAction<string>) {
      state.notifications = state.notifications.filter(
        (n) => n.key !== action.payload
      );
    },
    setFlowPreviewId(state, action: PayloadAction<string>) {
      state.selectedPreviewFlowId = action.payload;
    },
    unsetFlowPreviewId(state) {
      state.selectedPreviewFlowId = "";
    },
    setStepInputData(
      state,
      action: PayloadAction<{
        stepId: string;
        flowId: string;
        inputData: unknown;
      }>
    ) {
      const { inputData, stepId, flowId } = action.payload;
      state.stepUi[stepId] = {
        ...state.stepUi[stepId],
        flowId: flowId,
        inputData: inputData || state.stepUi[stepId]?.inputData || {},
      };
    },
    setUiDataForTab(
      state,
      action: PayloadAction<{
        stepId?: string;
        flowId?: string;
        schedulerId?: string;
        data: Partial<TabBaseUiConfig>;
      }>
    ) {
      setUiData(state, action.payload, action.payload.data);
    },
    setEntityValidationErrors(
      state,
      action: PayloadAction<{ id: string; errors: Array<FieldError> }>
    ) {
      const { id, errors } = action.payload;
      state.entityValidationErrors[id] = errors;
    },
    clearEntityValidationErrors(state, action: PayloadAction<string>) {
      delete state.entityValidationErrors[action.payload];
    },
    showFlowSourceView(state, action: PayloadAction<string>) {
      const flowId = action.payload;
      state.flowsSourceViewEnabled[flowId] = true;
    },
    hideFlowSourceView(state, action: PayloadAction<string>) {
      const flowId = action.payload;
      state.flowsSourceViewEnabled[flowId] = false;
    },
    removeFlowSourceView(state, action: PayloadAction<string>) {
      const flowId = action.payload;
      delete state.flowsSourceViewEnabled[flowId];
    },
    setUsername(state, action: PayloadAction<string | null>) {
      state.username = action.payload;
    },
    resetAllFlowsData(state, action: PayloadAction<string | undefined>) {
      resetAllOpenFlowsData(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getACEVersionAction.fulfilled, (state, action) => {
        state.version = action.payload;
      })
      .addCase(setWorkspaceHasConflictsFlagAction, (state) => {
        state.hasWorkspaceConflicts = true;
      })
      .addCase(showLocalRepositoryWarning, (state) => {
        state.localRepositoryWarning = true;
      })
      .addCase(hideLocalRepositoryWarning, (state) => {
        state.localRepositoryWarning = false;
      })
      .addCase(indexWorkspaceReferencesAction.pending, (state) => {
        state.isIndexing = true;
      })
      .addCase(indexWorkspaceReferencesAction.fulfilled, (state) => {
        state.isIndexing = false;
      })
      .addCase(initializeVariablesAction.fulfilled, (state, action) => {
        state.selectedVariablesId = action.payload;
      })
      .addCase(
        addNotification,
        (state, action: PayloadAction<NotificationOptionalKey>) => {
          const key = action.payload.key ?? v4();
          const isUnique = !state.notifications.find((n) => n.key === key);

          if (isUnique) {
            state.notifications.push({ ...action.payload, key });
          }
        }
      )
      .addCase(generateDataForInputSchemaAction.fulfilled, (state, action) => {
        const { sampleData, id } = action.payload;
        state.flowUi[id] = {
          ...state.flowUi[id],
          inputData: sampleData ?? {},
          outputData: {},
        };
      })
      .addCase(selectApiTreeEntity, (state, action: PayloadAction<string>) => {
        state.selectedApiTreeEntityId = action.payload;
      })
      .addMatcher(isWorkspaceConflictResolutionFulfilled, (state) => {
        state.hasWorkspaceConflicts = false;
      })
      .addMatcher(
        isAnyOf(isPending(testFlowAction, testStepAction, testSchedulerAction)),
        (state, action) => {
          setUiData(state, action.meta.arg, { loading: true });
        }
      )
      .addMatcher(
        isAnyOf(
          isFulfilled(testFlowAction, testStepAction, testSchedulerAction)
        ),
        (state, action) => {
          setUiData(state, action.meta.arg, { loading: false });
        }
      )
      .addMatcher(
        isAnyOf(
          isRejected(testSchedulerAction, testFlowAction, testStepAction)
        ),
        (state, action) => {
          setUiData(state, action.meta.arg, uiTabLoaderInitState);
        }
      )
      .addMatcher(
        isFulfilled(testSchedulerAction, testFlowAction, testStepAction),
        (state, action) => {
          setUiData(state, action.meta.arg, {
            outputData: action.payload.result,
            ...uiTabLoaderInitState,
          });
        }
      );
  },
});

export default slice.reducer;

export const {
  openSettings,
  closeSettings,
  openWorkspaceManager,
  closeWorkspaceManager,
  setDesignerLoading,
  selectFlowFolder,
  selectSchemaFolder,
  selectVirtualStepsFolder,
  toggleFlowFolder,
  toggleSchemasFolder,
  toggleApiFolder,
  toggleVirtualStepsFolder,
  selectStep,
  deselectStep,
  selectSchema,
  selectVariables,
  deselectSchema,
  selectVirtualStep,
  deselectVirtualStep,
  removeNotification,
  setFlowPreviewId,
  unsetFlowPreviewId,
  setEntityValidationErrors,
  clearEntityValidationErrors,
  showFlowSourceView,
  hideFlowSourceView,
  removeFlowSourceView,
  removeFlowDebugData,
  setStepInputData,
  removeStepDebugData,
  setUiDataForTab,
  setUsername,
  resetAllFlowsData,
} = slice.actions;
