import { createAction, createAsyncThunk, isRejected } from "@reduxjs/toolkit";
import posixPath from "@sapiens-digital/ace-designer-common/lib/helpers/posixPath";
import { isDir } from "services/fs-utils";

import { NotificationOptionalKey } from "../../model/notification";
import { GitDetails } from "../../model/workspace";
import { getACEVersion } from "../../services/designer";
import { gitCloneWithCredentials } from "../../services/git-utils";
import { SettingsManager } from "../../services/settingsManager";
import {
  createTemporaryFolder,
  resetTemporaryFiles,
} from "../../services/temporaryFiles";
import { extractZipToFolder } from "../../services/zip";
import {
  loadDesignerSettingsAction,
  switchWorkspaceAction,
} from "../settings/actions";
import { loadStepsSchemas } from "../steps-schemas/actions";
import { readError } from "../utils/readError";
import {
  createWorkspaceAction,
  loadWorkspacesAction,
} from "../workspaces/actions";
import { RootState } from "..";

export const initializeDesignerAction = createAsyncThunk(
  "designer/initialize",
  async (_, { dispatch }) => {
    SettingsManager.init();
    await dispatch(getACEVersionAction());
    await dispatch(loadStepsSchemas()).unwrap();
    const settings = await dispatch(loadDesignerSettingsAction()).unwrap();

    if (
      settings.repositoryUrl === undefined &&
      settings.repositoryToken === undefined
    ) {
      dispatch(showLocalRepositoryWarning());
    }

    const workspacesLocation = SettingsManager.getWorkspacesLocation();

    if (workspacesLocation === undefined) {
      throw new Error("workspacesLocation is undefined");
    }

    try {
      await resetTemporaryFiles(workspacesLocation);
    } catch (e) {
      console.error("Failed to clear temporary files:", readError(e));
    }

    const workspaces = await dispatch(loadWorkspacesAction(settings)).unwrap();

    if (!workspaces.length) {
      const createAction = await dispatch(
        createWorkspaceAction({
          ...settings,
          workspaceName: settings.repositoryDefaultBranch,
        })
      );

      if (isRejected(createAction)) {
        throw new Error("Failed to create workspace");
      }

      await dispatch(switchWorkspaceAction(createAction.payload.id)).unwrap();
      return;
    }

    if (settings.selectedWorkspaceId !== undefined) {
      const selectedWorkspaceExists = workspaces.some(
        (item) => item.id === settings.selectedWorkspaceId
      );

      if (selectedWorkspaceExists) {
        await dispatch(
          switchWorkspaceAction(settings.selectedWorkspaceId)
        ).unwrap();
        return;
      }
    }

    const defaultBranchWorkspace = workspaces.find(
      (item) => item.name === settings.repositoryDefaultBranch
    );

    if (defaultBranchWorkspace !== undefined) {
      await dispatch(switchWorkspaceAction(defaultBranchWorkspace.id)).unwrap();
      return;
    }

    await dispatch(switchWorkspaceAction(workspaces[0].id)).unwrap();
    return;
  }
);

export const getACEVersionAction = createAsyncThunk(
  "designer/getACEVersion",
  async () => getACEVersion()
);

export const setWorkspaceHasConflictsFlagAction = createAction<void>(
  "designer/setWorkspaceHasConflictsFlag"
);

export const showLocalRepositoryWarning = createAction(
  "designer/showLocalRepositoryWarning"
);
export const hideLocalRepositoryWarning = createAction(
  "designer/hideLocalRepositoryWarning"
);

export const unzipToTemporaryDirectory = createAsyncThunk<
  string,
  { zipData: Buffer },
  { state: RootState }
>("designer/unzipToTemporaryDirectory", async ({ zipData }, { getState }) => {
  const folderPath = await createTemporaryFolder(
    SettingsManager.getWorkspacesLocation()
  );

  await extractZipToFolder(folderPath, zipData);
  return folderPath;
});

export const pullGitRepositoryToTemporaryDirectory = createAsyncThunk<
  string,
  { gitData: GitDetails },
  { state: RootState }
>(
  "designer/pullGitRepositoryToTemporaryDirectory",
  async ({ gitData }, { getState }) => {
    const {
      gitUrl,
      branchOrTagName,
      gitUsername,
      gitPassword,
      sourcePath,
    } = gitData;

    const folderPath = await createTemporaryFolder(
      SettingsManager.getWorkspacesLocation()
    );

    await gitCloneWithCredentials(
      gitUrl,
      branchOrTagName,
      gitUsername,
      gitPassword,
      folderPath
    );

    if (sourcePath) {
      const srcPath = posixPath.join(folderPath, sourcePath);

      if (!(await isDir(srcPath))) {
        throw new Error(`Invalid path to ACE workspace: ${sourcePath}`);
      }

      return srcPath;
    }

    return folderPath;
  }
);

export const addNotification = createAction<NotificationOptionalKey>(
  "designer/addNotification"
);

export const selectApiTreeEntity = createAction<string>(
  "designer/selectApiTreeEntity"
);
