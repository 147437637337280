import { Logger } from "@sapiens-digital/ace-designer-common/lib/logging";
import * as workspacePaths from "@sapiens-digital/ace-designer-common/lib/model/workspacePaths";
import set from "lodash/set";
import path from "path";

import {
  SerializedEnvVariable,
  SerializedEnvVariables,
} from "../../model/variable";
import { createEmptyVariable } from "../../utils/factory";
import { createSortYamlMapEntries } from "../../utils/sortYaml";
import { readYaml, YAMLStringifyOptions } from "../fs-utils";

import { PlainObject, V1EntityMigrator } from "./migrate";
import { saveAsYaml } from "./migrateUtils";

const ENV_VARIABLE_KEYS = ["name", "value"];

const yamlOptions: YAMLStringifyOptions = {
  sortMapEntries: createSortYamlMapEntries(ENV_VARIABLE_KEYS),
};

const migrateEnvVariable: V1EntityMigrator = async ({
  targetRepositoryLocation,
  fileBasename,
  repositoryLocation,
  content,
  overwriteExisting,
}) => {
  content = content as PlainObject[];
  const migration = {};
  fileBasename = "env";
  const sourceFile = await readEnvVariableFile(repositoryLocation as string);

  for (const variable of content) {
    variable["name"] = variable["key"];
    delete variable["key"];
  }

  if (overwriteExisting) {
    sourceFile.variables = (content as unknown) as SerializedEnvVariable[];
  } else {
    const variables = (content as unknown) as SerializedEnvVariable[];
    variables.forEach((variable) => {
      const cpyVariable = sourceFile.variables.find(
        (v) => v.name === variable.name
      );
      if (cpyVariable) {
        cpyVariable.value = variable.value;
      } else sourceFile.variables.push(variable);
    });
  }

  set(migration, "variables", sourceFile.variables);
  await saveAsYaml(
    targetRepositoryLocation,
    workspacePaths.WORKSPACE_VARIABLES,
    fileBasename,
    migration,
    yamlOptions
  );
  return [];
};

async function readEnvVariableFile(
  repositoryLocation: string
): Promise<SerializedEnvVariables> {
  try {
    return (await readYaml(
      path.join(
        repositoryLocation,
        workspacePaths.WORKSPACE_VARIABLES,
        "env.yaml"
      )
    )) as SerializedEnvVariables;
  } catch (e) {
    Logger.getLogger("migrateApi").debug("Creating new env.yaml file.");
    console.warn("Cannot read env variable file", e);
    return createEmptyVariable();
  }
}

export default migrateEnvVariable;
