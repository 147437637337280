import { RUNTIME_SERVER_DEFAULT_PORT } from "../constant/serverConfig";
import { Settings } from "../model/settings";

import { getEnvironmentVariables } from "./settings";
import { Environment, getEnvironment } from "./workspace";

/**
 * This class saves the designer settings once when the application started.
 * Changes made in the UI should not be updated here.
 */

export const DESIGNER_SETTINGS_KEY = "DESIGNER_SETTINGS";

export class SettingsManager {
  private static designerSettings: Settings;
  private static env: Record<string, string>;

  public static setDesignerSettings(initSettings: Settings): void {
    if (this.designerSettings) {
      return;
    }

    this.designerSettings = initSettings;

    // If settings are present in session storage then use it.
    const settings = sessionStorage.getItem(DESIGNER_SETTINGS_KEY);

    if (settings) {
      try {
        this.designerSettings = JSON.parse(settings);
      } catch (err) {
        sessionStorage.setItem(
          DESIGNER_SETTINGS_KEY,
          JSON.stringify(this.designerSettings)
        );
      }
    } else {
      sessionStorage.setItem(
        DESIGNER_SETTINGS_KEY,
        JSON.stringify(this.designerSettings)
      );
    }
  }

  public static async init(): Promise<void> {
    this.env = await getEnvironmentVariables();
  }

  public static getDesignerSettings(): Settings {
    return this.designerSettings;
  }

  public static getSwaggerServerUrl(): string | undefined {
    const isElectron = getEnvironment() === Environment.Electron;

    if (isElectron) {
      const port =
        this.designerSettings.runtimeServerPort || RUNTIME_SERVER_DEFAULT_PORT;
      return `http://localhost:${port}`;
    }

    if (process.env.REACT_APP_DEV_API_BASE_URL) {
      return `${process.env.REACT_APP_DEV_API_BASE_URL}/api`;
    }
  }

  public static constructApiDefinitionUrl(apiName: string): string | undefined {
    if (process.env.REACT_APP_DEV_API_BASE_URL) {
      const trimmedBaseUrl = this.trimBaseUrl(
        process.env.REACT_APP_DEV_API_BASE_URL
      );
      return `#/api-definition?url=${trimmedBaseUrl}api-spec?api=${apiName}`;
    }
  }

  public static getWorkspacesLocation(): string {
    return this.designerSettings.workspacesLocation;
  }

  public static getIsDeprecatedStepsEnabled(): boolean {
    return this.env && this.env.REACT_APP_ENABLE_DEPRECATED_STEPS === "true";
  }

  private static trimBaseUrl(baseUrl: string): string {
    return baseUrl.replace(/^((\w+:)?\/\/[^/]+\/?).*$/, "$1");
  }
}
