import { Component, ReactNode } from "react";
import { Alert, Box, SxProps, Theme } from "@mui/material";

import ACEBackgroundLogo from "./icons/ACEBackgroundLogo";

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
}

const aceLogo: SxProps<Theme> = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  height: "80%",
};

class ErrorBoundary extends Component<Props, State> {
  // eslint-disable-next-line @typescript-eslint/explicit-member-accessibility
  state: State = {
    hasError: false,
  };

  // eslint-disable-next-line @typescript-eslint/explicit-member-accessibility
  componentDidCatch(): void {
    this.setState({ hasError: true });
  }

  // eslint-disable-next-line @typescript-eslint/explicit-member-accessibility
  render(): ReactNode {
    if (this.state.hasError) {
      return (
        <Box sx={{ height: "100%", width: "100%" }}>
          <Alert
            sx={{
              marginTop: "20px",
              marginBottom: "20px",
              width: "50%",
              margin: "auto",
            }}
            severity="error"
          >
            Something went wrong!
          </Alert>
          <Box sx={aceLogo}>
            <ACEBackgroundLogo />
          </Box>
        </Box>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
