import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { AppEventName } from "@sapiens-digital/ace-designer-common";

import { Settings } from "../../model/settings";
import { emitAppEvent } from "../../services/appEvents";
import {
  loadDesignerSettings,
  saveDesignerSettings,
} from "../../services/settings";
import { SettingsManager } from "../../services/settingsManager";
import { hideLocalRepositoryWarning } from "../designer/actions";
import { RootState } from "../index";
import { areSettingsValid } from "../utils/areSettingsValid";
import { assertWorkspace } from "../utils/assertEntities";
import {
  deleteLocalWorkspacesAction,
  updateAndSetupWorkspaceAction,
} from "../workspaces/actions";
import { workspaceSelectors } from "../workspaces/selectors";

export const loadDesignerSettingsAction = createAsyncThunk(
  "settings/load",
  async () => {
    const settings = await loadDesignerSettings();
    SettingsManager.setDesignerSettings(settings);
    return settings;
  }
);

export const saveDesignerSettingsAction = createAsyncThunk<
  Settings,
  Settings,
  { state: RootState }
>("settings/save", async (newSettings: Settings, { getState, dispatch }) => {
  const { settings } = getState();
  const settingCopy = { ...newSettings };

  if (settings.repositoryUrl !== newSettings.repositoryUrl) {
    await dispatch(deleteLocalWorkspacesAction());
    settingCopy.selectedWorkspaceId = undefined;
  }

  if (areSettingsValid(newSettings)) {
    dispatch(hideLocalRepositoryWarning());
  }

  await saveDesignerSettings(settingCopy);
  return settingCopy;
});

export const switchWorkspaceAction = createAsyncThunk(
  "settings/switchWorkspace",
  async (workspaceId: string, { getState, dispatch }) => {
    const state = getState() as RootState;
    const settings = { ...state.settings, selectedWorkspaceId: workspaceId };

    const workspace = workspaceSelectors.selectById(state, workspaceId);
    assertWorkspace(workspace, workspaceId);

    dispatch(selectWorkspaceAction(workspaceId));
    await saveDesignerSettings(settings);

    if (areSettingsValid(settings)) {
      await dispatch(
        updateAndSetupWorkspaceAction({
          ...settings,
          workspaceName: workspace.name,
        })
      ).unwrap();
    }

    emitAppEvent(AppEventName.LoadedWorkspace, {
      workspaceLocation: workspace.location,
      repositoryRoot: workspace.repositoryRoot,
    });
  }
);

export const selectWorkspaceAction = createAction<string>(
  "settings/selectWorkspace"
);
