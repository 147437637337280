import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import {
  Action,
  AnyAction,
  combineReducers,
  configureStore,
  ThunkAction,
} from "@reduxjs/toolkit";

import apis from "./apis/reducers";
import designer from "./designer/reducers";
import errorHandlers from "./error-handlers/reducers";
import flows from "./flows/reducers";
import { errorLoggerMiddleware } from "./middlewares/ErrorLoggerMiddleware";
import { listenerMiddleware } from "./middlewares/ListenerMiddleware";
import referencesReducer from "./references/reducers";
import remotes from "./remotes/reducers";
import schedules from "./schedules/reducers";
import schemas from "./schemas/reducers";
import settings from "./settings/reducers";
import stepsSchemas from "./steps-schemas/reducers";
import variables from "./variables/reducers";
import virtualSteps from "./virtual-steps/reducers";
import workspaces from "./workspaces/reducers";

// Combining all reducers from all slices together
const combinedReducer = combineReducers({
  workspaces,
  settings,
  designer,
  remotes,
  flows,
  apis,
  schedules,
  virtualSteps,
  stepsSchemas,
  schemas,
  errorHandlers,
  variables,
});

// RootState
export type RootState = ReturnType<typeof combinedReducer>;

export const rootReducer = (
  state: RootState | undefined,
  action: AnyAction
): RootState => {
  const updatedState = combinedReducer(state, action);
  // order is important, reference reducer can depend on the updated state (entities, added, removed, etc)
  return referencesReducer(updatedState, action);
};

// Creating Application state store
export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      errorLoggerMiddleware,
      listenerMiddleware.middleware
    ),
});

export type Store = typeof store;

export type AppDispatch = typeof store.dispatch;

export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>;

export const useAppDispatch = (): AppDispatch => useDispatch<AppDispatch>();

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
