import { SchemaRepository } from "../../repositories";
import {
  ReferenceResolution,
  ResolvedSchemaObject,
  SchemaObject,
} from "../ReferenceResolution";
import posixPath from "../../helpers/posixPath";
import { WORKSPACE_API_SCHEMAS } from "../../model";
import { BaseReferenceResolution } from "../BaseReferenceResolution";

type Ref = string;
type SchemaMap = Record<Ref, SchemaObject>;
type RefVariant = {
  ref: Ref;
  refRelative: Ref;
};
type ResolvedRef = {
  ref: Ref;
  schema: SchemaObject;
};
type ResolvedSchema = {
  schema: SchemaObject;
  refs: RefVariant[];
};
type ResolvedSchemaMap = Record<Ref, ResolvedSchema>;
type ResolutionPass = {
  refsThatHaveBeenFound: Ref[];
  refsThatNeedToBeResolved: Ref[];
  resolvedSchemas: ResolvedSchemaMap;
};
type ResolutionInitialPass = {
  initialSchema: ResolvedSchema;
  initialPass: ResolutionPass;
};

const SCHEMAS_FOLDER = `${posixPath.normalize(WORKSPACE_API_SCHEMAS)}/`;

/**
 * References can only point to `${repoRoot}/api/schemas` directory and inside of it.
 * References are expected to be relative to own path, ex: `IDIT/SchemaA.yaml` would reference `SchemaB.yaml` as `../SchemaB.yaml`
 * The root path can be specified via `pathFromRepoRoot`
 * ex: if `pathFromRepoRoot` is `apis`, schema `SchemaA` must be referenced as `schemas/SchemaA.yaml`
 * ex: if `pathFromRepoRoot` is `flows`, schema `SchemaA` must be referenced as `../apis/schemas/SchemaA.yaml`
 */
export class ACE5ReferenceResolution
  extends BaseReferenceResolution
  implements ReferenceResolution {
  public constructor(schemaRepository: SchemaRepository) {
    super(schemaRepository);
  }

  public async deepResolveSchema(
    schemaName: string
  ): Promise<SchemaObject | undefined> {
    const schema = await this.lookupSchema(schemaName);
    if (!schema) return;
    return this.deepResolveSchemaObject(
      schema,
      `${SCHEMAS_FOLDER}${posixPath.dirname(schemaName)}`
    );
  }

  public getOperationSchemaName(ref = ""): string {
    return ref.replace("schemas/", "");
  }

  public async deepResolveSchemaObject(
    schema: SchemaObject,
    pathFromRepoRoot?: string
  ): Promise<SchemaObject> {
    this.assertIsDefined(pathFromRepoRoot);

    const { initialSchema, initialPass } = this.doInitialPass(
      schema,
      pathFromRepoRoot
    );
    let pass = initialPass;
    while (pass.refsThatNeedToBeResolved.length > 0) {
      pass = await this.doFollowingPass(pass);
    }

    const noSchemasResolved = Object.keys(pass.resolvedSchemas).length < 1;
    if (noSchemasResolved) {
      return initialSchema.schema;
    }

    return this.combineResolvedSchemas(initialSchema, pass.resolvedSchemas);
  }

  private assertIsDefined(value: string | undefined): asserts value is string {
    if (value === undefined) {
      throw new Error(
        "ACE 5 reference resolution requires specifying the folder path in which the reference exists, since contained references are resolved as relative paths"
      );
    }
  }

  private doInitialPass(
    schema: SchemaObject,
    pathFromRepoRoot: string
  ): ResolutionInitialPass {
    const initialSchema: ResolvedSchema = {
      schema,
      refs: this.readRefsAndMakeRelative(schema, pathFromRepoRoot, true),
    };

    const relativeRefs = this.selectRelativeRefs(initialSchema.refs);
    const initialPass: ResolutionPass = {
      refsThatNeedToBeResolved: relativeRefs,
      refsThatHaveBeenFound: relativeRefs,
      resolvedSchemas: {},
    };

    return { initialSchema, initialPass };
  }

  private readRefsAndMakeRelative(
    schema: SchemaObject,
    dir: string,
    isInitialPass?: boolean
  ): RefVariant[] {
    const refs: RefVariant[] = [];
    JSON.stringify(schema, (key, value) => {
      if (!this.isRef(key, value)) return value;
      const ref = posixPath.normalize(value);
      const refInDir = posixPath.normalize(posixPath.join(dir, ref));
      if (isInitialPass) {
        const isSchemaInSchemasFolder = refInDir.startsWith(SCHEMAS_FOLDER);
        if (isSchemaInSchemasFolder) {
          refs.push({ ref, refRelative: this.omitTwoDirectories(refInDir) });
        }
      } else {
        refs.push({ ref, refRelative: refInDir });
      }
      return value;
    });
    return refs;
  }

  private isRef(key: string, value: unknown): value is string {
    return key === "$ref" && typeof value === "string";
  }

  private sanitizeRef(ref: Ref): Ref {
    const sanitized = ref.replaceAll("/", "-");
    const withoutYaml = sanitized.endsWith(".yaml")
      ? sanitized.slice(0, -".yaml".length)
      : sanitized;
    return withoutYaml;
  }

  private async doFollowingPass(
    previousPass: ResolutionPass
  ): Promise<ResolutionPass> {
    const pass: ResolutionPass = { ...previousPass };

    const resolvedRefs = await this.resolveRefs(pass.refsThatNeedToBeResolved);
    const newRefsThatNeedToBeResolved: Ref[] = [];
    resolvedRefs.forEach(({ schema, ref }) => {
      const refs = this.readRefsAndMakeRelative(schema, posixPath.dirname(ref));
      const relativeRefs = this.selectRelativeRefs(refs);
      const newUniqueRefs = relativeRefs.filter(
        (ref) => !pass.refsThatHaveBeenFound.includes(ref)
      );
      pass.refsThatHaveBeenFound.push(...newUniqueRefs);
      newRefsThatNeedToBeResolved.push(...newUniqueRefs);
      pass.resolvedSchemas[ref] = { schema, refs };
    });

    return { ...pass, refsThatNeedToBeResolved: newRefsThatNeedToBeResolved };
  }

  private async resolveRefs(relativeRefs: Ref[]): Promise<ResolvedRef[]> {
    const schemas = await Promise.all(
      relativeRefs.map(async (ref) => {
        const schema = await this.lookupSchema(ref);
        if (!this.isObject(schema)) return;
        return { ref, schema };
      })
    );

    const resolvedRefs: ResolvedRef[] = [];
    schemas.forEach((resolvedRef) => {
      resolvedRef && resolvedRefs.push(resolvedRef);
    });
    return resolvedRefs;
  }

  private omitTwoDirectories(ref: Ref): Ref {
    const dirCount = 2;
    return posixPath.join(...ref.split("/").slice(dirCount));
  }

  private isObject(value: unknown): value is SchemaObject {
    return typeof value === "object" && value !== null;
  }

  private selectRelativeRefs(refs: RefVariant[]): Ref[] {
    return refs.map(({ refRelative }) => refRelative);
  }

  private combineResolvedSchemas(
    baseSchema: ResolvedSchema,
    resolvedSchemas: ResolvedSchemaMap
  ): SchemaObject {
    const validRefs = Object.keys(resolvedSchemas);
    const localizedBaseSchema = this.localizeResolvedSchema(
      baseSchema,
      validRefs
    );
    const localizedSchemas = this.localizeResolvedSchemas(
      resolvedSchemas,
      validRefs
    );
    return this.placeReferencesIntoComponentsSchemas(
      localizedBaseSchema,
      localizedSchemas
    );
  }

  private localizeResolvedSchema(
    { schema, refs }: ResolvedSchema,
    validRefs: Ref[]
  ): SchemaObject {
    const resolvedSchema = JSON.stringify(schema, (key, value) => {
      if (!this.isRef(key, value)) return value;
      const ref = refs.find(({ ref }) => ref === value);
      if (!ref) return value;
      if (!validRefs.includes(ref.refRelative)) return value;
      return `#/components/schemas/${this.sanitizeRef(ref.refRelative)}`;
    });
    return JSON.parse(resolvedSchema);
  }

  private localizeResolvedSchemas(
    schemas: ResolvedSchemaMap,
    validRefs: Ref[]
  ): SchemaMap {
    const entries = Object.entries(schemas);
    return entries.reduce(
      (prev, [ref, schema]) => ({
        ...prev,
        [this.sanitizeRef(ref)]: this.localizeResolvedSchema(schema, validRefs),
      }),
      {} as SchemaMap
    );
  }

  private placeReferencesIntoComponentsSchemas(
    base: SchemaObject,
    resolvedSchemas: Record<Ref, SchemaObject>
  ): SchemaObject {
    const resolvedSchema = base as ResolvedSchemaObject;
    return {
      ...resolvedSchema,
      components: {
        ...resolvedSchema?.components,
        schemas: {
          ...resolvedSchema?.components?.schemas,
          ...resolvedSchemas,
        },
      },
    };
  }
}
