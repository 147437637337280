import { RepositoryDetails } from "../model/git";
import {
  AceHeaders,
  HEADER_REPOSITORY,
  HEADER_TOKEN,
  HEADER_BRANCH,
  HEADER_AUTHORIZATION,
  HEADER_COMMIT,
  HEADER_USERNAME,
} from "../model/aceHeaders";

export const createAceHeaders = (
  repoDetails: RepositoryDetails,
  authorizationToken: string
): AceHeaders => {
  return {
    [HEADER_REPOSITORY]: repoDetails.repository,
    [HEADER_TOKEN]: repoDetails.token,
    [HEADER_BRANCH]: repoDetails.branch,
    [HEADER_AUTHORIZATION]: authorizationToken,
    [HEADER_COMMIT]: repoDetails.commit,
    [HEADER_USERNAME]: repoDetails.username,
  };
};
