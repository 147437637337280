import React from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import BrightnessAutoSharpIcon from "@mui/icons-material/BrightnessAutoSharp";
import DashboardIcon from "@mui/icons-material/Dashboard";
import DateRangeIcon from "@mui/icons-material/DateRange";
import DeviceHubIcon from "@mui/icons-material/DeviceHub";
import ErrorIcon from "@mui/icons-material/Error";
import HelpIcon from "@mui/icons-material/Help";
import PagesIcon from "@mui/icons-material/Pages";
import SettingsIcon from "@mui/icons-material/Settings";
import SettingsEthernetIcon from "@mui/icons-material/SettingsEthernet";
import {
  Box,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  SxProps,
  Theme,
  Tooltip,
  Typography,
} from "@mui/material";
import { Environment, getEnvironment } from "services/workspace";

import ACELogo from "../components/icons/ACELogo";
import { openSettings } from "../store/designer/reducers";

import { UserLogout } from "./user-logout/UserLogout";
import {
  APIS_PATH,
  ENV_VARS_PATH,
  ERR_HANDLERS_PATH,
  FLOWS_PATH,
  SCHEDULER_PATH,
  SCHEMAS_PATH,
  VIRTUAL_STEP_PATH,
} from "./Designer";

const sxLeftMenu: SxProps<Theme> = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  backgroundColor: "primaryVariant",
  width: "110px",
};

const sxItem: SxProps<Theme> = {
  display: "flex",
  justifyContent: "center",
  height: "80px",
  width: "84px",
  margin: "3px 12px",
  padding: "8px",
  paddingTop: "10px",
  borderRadius: "3px",
  "&:hover": {
    backgroundColor: "#117DB0",
  },
  "&.Mui-selected": {
    backgroundColor: "secondary.main",
    "&:hover": {
      backgroundColor: "secondary.main",
    },
  },
};

interface MenuItemProps {
  name?: string;
  icon: JSX.Element;
  to?: string;
  label: string;
}

export const ACE5_DOC_LINK = "https://dev.ace-community.sapienspaas.com/docs";

export const FlowIcon = DeviceHubIcon;
export const ApiIcon = BrightnessAutoSharpIcon;
export const SchedulerIcon = DateRangeIcon;
export const SchemaIcon = DashboardIcon;
export const VirtualStepIcon = PagesIcon;
export const ErrorHandlerIcon = ErrorIcon;
export const VariableIcon = SettingsEthernetIcon;

const MenuItem: React.FC<MenuItemProps> = (props) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleClick = () => {
    if (props.to !== undefined) {
      navigate(props.to);
    }
  };

  return (
    <ListItem
      onClick={handleClick}
      button
      sx={sxItem}
      selected={location.pathname === props.to}
      disablePadding
    >
      <Tooltip title={props.label} enterDelay={500} enterNextDelay={500}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ListItemIcon sx={{ color: "white", justifyContent: "center" }}>
            {props.icon}
          </ListItemIcon>
          <Typography
            variant="caption"
            sx={{
              textAlign: "center",
              color: "white",
              typography: "caption",
              fontSize: "0.7rem",
              marginTop: "4px",
            }}
          >
            {props.name}
          </Typography>
        </Box>
      </Tooltip>
    </ListItem>
  );
};

export const LeftMenu: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isElectron = getEnvironment() === Environment.Electron;

  const openSettingsHandler = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    const element = e.target as HTMLButtonElement;
    element.blur();

    dispatch(openSettings());
  };

  const handleLogoButtonClick = () => {
    navigate("");
  };

  return (
    <Box sx={sxLeftMenu}>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          overflow: "hidden",
        }}
      >
        <IconButton
          sx={{ width: 40, height: 30, margin: "40px 0 40px -8px" }}
          onClick={handleLogoButtonClick}
          data-testid="logo"
          size="large"
        >
          <ACELogo />
        </IconButton>
        <List
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: 0,
            overflowX: "hidden",
            overflowY: "auto",
            scrollbarWidth: "none",
            "&::-webkit-scrollbar": {
              width: 0,
            },
          }}
        >
          <MenuItem
            name="FLOWS"
            icon={<FlowIcon />}
            to={`/${FLOWS_PATH}`}
            label="ACE flows"
          />
          <MenuItem
            name="API"
            icon={<ApiIcon />}
            to={`/${APIS_PATH}`}
            label="Dynamic APIs"
          />
          <MenuItem
            name="SCHEDULER"
            icon={<SchedulerIcon />}
            to={`/${SCHEDULER_PATH}`}
            label="Scheduled flows"
          />
          <MenuItem
            name="SCHEMAS"
            icon={<SchemaIcon />}
            to={`/${SCHEMAS_PATH}`}
            label="Schemas"
          />
          <MenuItem
            name="VIRTUAL"
            icon={<VirtualStepIcon />}
            to={`/${VIRTUAL_STEP_PATH}`}
            label="Virtual steps"
          />
          <MenuItem
            name="HANDLERS"
            icon={<ErrorHandlerIcon />}
            to={`/${ERR_HANDLERS_PATH}`}
            label="Error handlers"
          />
          <MenuItem
            name="VARIABLES"
            icon={<VariableIcon />}
            to={`/${ENV_VARS_PATH}`}
            label="Variables"
          />
        </List>
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <UserLogout />
        <Tooltip title="Settings" enterDelay={500}>
          <IconButton
            sx={{ color: "white" }}
            onClick={openSettingsHandler}
            data-testid="settings"
            size="large"
          >
            <SettingsIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Help" enterDelay={500}>
          <IconButton
            sx={{ color: "white" }}
            onClick={() =>
              isElectron
                ? window.open(ACE5_DOC_LINK, "_blank", "height=900,width=1280")
                : window.open(ACE5_DOC_LINK)
            }
            data-testid="help"
            size="large"
          >
            <HelpIcon />
          </IconButton>
        </Tooltip>
      </Box>
    </Box>
  );
};

export default LeftMenu;
