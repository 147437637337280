import { createSlice } from "@reduxjs/toolkit";

import { Settings } from "../../model/settings";

import {
  loadDesignerSettingsAction,
  saveDesignerSettingsAction,
  selectWorkspaceAction,
} from "./actions";

const settingsSlice = createSlice({
  name: "settings",
  initialState: {} as Settings,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      loadDesignerSettingsAction.fulfilled,
      (state, action) => action.payload
    );
    builder.addCase(
      saveDesignerSettingsAction.fulfilled,
      (state, action) => action.payload
    );
    builder.addCase(selectWorkspaceAction, (state, action) => {
      state.selectedWorkspaceId = action.payload;
    });
  },
});

export default settingsSlice.reducer;
