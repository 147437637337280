import jwtDecode from "jwt-decode";

type JWTToken = {
  data: {
    date: number;
    username: string;
    roles: string[];
  };
  iat: number;
  exp: number;
};

const extractJWTData = (newToken: string): JWTToken | null => {
  try {
    return jwtDecode<JWTToken>(newToken);
  } catch (e) {
    return null;
  }
};

export const getUsernameFromToken = (
  newToken: string | null
): string | null => {
  if (!newToken) {
    return null;
  }

  return extractJWTData(newToken)?.data.username || null;
};

export const getTimeRemainingToExpire = (
  newToken: string | null
): number | undefined => {
  if (!newToken) {
    return;
  }

  const exp = extractJWTData(newToken)?.exp;
  if (exp) {
    return exp * 1000 - Date.now();
  }
};
