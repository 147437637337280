import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import get from "lodash/get";
import { indexReferences } from "services/indexing/indexReferences";
import { selectSchemaPaths } from "store/schemas/selectors";

import { FileNode } from "../../model/file";
import {
  ExtendedSettings,
  Workspace,
  WorkspaceFolder,
} from "../../model/workspace";
import { getFileNameFromRegistry } from "../../services/fs-utils";
import { pushAllEntityChangesToGit } from "../../services/git-utils";
import { getFolderFileNodeIds } from "../../services/nodes";
import {
  createWorkspace,
  deleteWorkspace,
  importWorkspace,
  loadWorkspaces,
  openWorkspace,
  updateFolder,
  updateWorkspace,
} from "../../services/workspace";
import { MigrationError } from "../../services/workspace/migrate";
import { refreshApisAction } from "../apis/actions";
import { initializeApisAction } from "../apis/initializeApisAction";
import { selectSelectedApiOperationsByTag } from "../apis/selectors";
import { refreshErrorHandlersAction } from "../error-handlers/actions";
import { refreshFlowsAction } from "../flows/actions";
import { selectFlowPaths } from "../flows/selectors";
import { refreshSchedulesAction } from "../schedules/actions";
import { refreshSchemaAction } from "../schemas/actions";
import { saveDesignerSettingsAction } from "../settings/actions";
import { assertSettings, assertWorkspace } from "../utils/assertEntities";
import { getObjectPathToFileNode } from "../utils/getObjectPathToFileNode";
import { refreshVariablesAction } from "../variables/actions";
import { initializeVariablesAction } from "../variables/initializeVariablesAction";
import { refreshVirtualStepsAction } from "../virtual-steps/actions";
import { RootState } from "..";

import { loadWorkspaceAction } from "./loadWorkspaceAction";
import { selectSelectedWorkspace, workspaceSelectors } from "./selectors";
import { updateWorkspaceAction } from "./updateWorkspaceAction";

export const loadWorkspacesAction = createAsyncThunk(
  "workspaces/load",
  async (parameters: { repositoryRoot: string }) =>
    loadWorkspaces(parameters.repositoryRoot)
);

export const createWorkspaceAction = createAsyncThunk(
  "workspaces/create",
  async (parameters: {
    workspaceName: string;
    repositoryRoot: string;
    repositoryUrl?: string;
    repositoryToken?: string;
    repositoryDefaultBranch?: string;
    repositoryUsername?: string;
  }) =>
    createWorkspace(
      parameters.workspaceName,
      parameters.repositoryRoot,
      parameters.repositoryUrl,
      parameters.repositoryToken,
      parameters.repositoryDefaultBranch,
      parameters.repositoryUsername
    )
);

export const updateAndSetupWorkspaceAction = createAsyncThunk(
  "workspace/updateAndSetup",
  async (parameters: ExtendedSettings, { dispatch }) => {
    const workspace = await dispatch(
      updateWorkspaceAction(parameters)
    ).unwrap();
    await dispatch(preloadEntitiesAction(workspace)).unwrap();

    return workspace;
  }
);

export const loadAndSetupWorkspaceAction = createAsyncThunk(
  "workspace/loadAndSetup",
  async (parameters: ExtendedSettings, { dispatch }) => {
    const workspace = await dispatch(loadWorkspaceAction(parameters)).unwrap();
    await dispatch(preloadEntitiesAction(workspace)).unwrap();

    return workspace;
  }
);

export const indexWorkspaceReferencesAction = createAsyncThunk(
  "workspace/indexReferences",
  async (workspace: Workspace, { getState }) => {
    const state = getState() as RootState;
    const flowPaths = Object.keys(selectFlowPaths(state));
    const schemaPaths = Object.keys(selectSchemaPaths(state));
    const operations = selectSelectedApiOperationsByTag(state);

    indexReferences({ flowPaths, schemaPaths, operations }, workspace);
  }
);

const preloadEntitiesAction = createAsyncThunk(
  "workspace/preloadEntities",
  async (workspace: Workspace, { dispatch }) => {
    await dispatch(
      refreshSchedulesAction({
        wsId: workspace.id,
        entityIds: getFolderFileNodeIds(workspace.schedules),
      })
    ).unwrap();
    await dispatch(
      refreshErrorHandlersAction({
        wsId: workspace.id,
        entityIds: getFolderFileNodeIds(workspace.errorHandlers),
      })
    ).unwrap();
    await dispatch(
      refreshVirtualStepsAction({
        wsId: workspace.id,
        entityIds: getFolderFileNodeIds(workspace.virtualSteps),
      })
    ).unwrap();
    await dispatch(initializeVariablesAction()).unwrap();
    await dispatch(initializeApisAction()).unwrap();
    dispatch(indexWorkspaceReferencesAction(workspace)).unwrap();
  }
);

export const refreshWorkspaceAction = createAsyncThunk<
  Workspace,
  { workspace: Workspace; settings: ExtendedSettings },
  { state: RootState }
>("workspace/refresh", async ({ workspace, settings }) => {
  const updatedWorkspace = await updateWorkspace(
    settings.workspaceName,
    settings.repositoryRoot,
    settings.repositoryUrl,
    settings.repositoryToken,
    settings.repositoryUsername
  );

  return {
    ...updatedWorkspace,
    flowIds: workspace.flowIds,
    apiIds: workspace.apiIds,
    selectedFlowId: workspace?.selectedFlowId,
  };
});

export const refreshAndSetupWorkspaceAction = createAsyncThunk<
  Workspace,
  void,
  { state: RootState }
>("workspace/refreshAndSetup", async (_, { getState, dispatch }) => {
  const state = getState();
  const workspace = selectSelectedWorkspace(state);
  assertWorkspace(workspace);
  assertSettings(state.settings);

  const settings = { ...state.settings, workspaceName: workspace.name };
  const updatedWorkspace = await dispatch(
    refreshWorkspaceAction({ workspace, settings })
  ).unwrap();

  await Promise.all([
    dispatch(
      refreshSchedulesAction({
        wsId: updatedWorkspace.id,
        entityIds: updatedWorkspace.schedules.map((e) => e.id),
      })
    ).unwrap(),
    dispatch(
      refreshErrorHandlersAction({
        wsId: updatedWorkspace.id,
        entityIds: updatedWorkspace.errorHandlers.map((e) => e.id),
      })
    ).unwrap(),
    dispatch(
      refreshSchemaAction({
        wsId: updatedWorkspace.id,
        entityIds: state.schemas.map((e) => e.present.id),
      })
    ).unwrap(),
    dispatch(
      refreshFlowsAction({
        wsId: updatedWorkspace.id,
        entityIds: updatedWorkspace.flowIds,
      })
    ).unwrap(),
    dispatch(
      refreshApisAction({
        wsId: updatedWorkspace.id,
        entityIds: updatedWorkspace.apiIds,
      })
    ).unwrap(),
    dispatch(
      refreshVariablesAction({
        wsId: updatedWorkspace.id,
        entityIds: state.variables.map((e) => e.present.id),
      })
    ).unwrap(),
    dispatch(
      refreshVirtualStepsAction({
        wsId: updatedWorkspace.id,
        entityIds: getFolderFileNodeIds(workspace.virtualSteps),
      })
    ),
  ]);

  await Promise.all([
    dispatch(initializeVariablesAction()).unwrap(),
    dispatch(initializeApisAction()).unwrap(),
  ]);

  return updatedWorkspace;
});

export const openWorkspaceAction = createAsyncThunk(
  "workspaces/open",
  async (parameters: {
    workspaceName: string;
    repositoryRoot: string;
    repositoryUrl?: string;
    repositoryToken?: string;
    repositoryUsername?: string;
  }) => {
    assertSettings(parameters);
    return await openWorkspace(
      parameters.workspaceName,
      parameters.repositoryRoot,
      parameters.repositoryUrl,
      parameters.repositoryToken,
      parameters.repositoryUsername
    );
  }
);

export const deleteWorkspaceAction = createAsyncThunk<
  string,
  string,
  { state: RootState }
>("workspace/delete", async (workspaceId: string, { getState, dispatch }) => {
  const state = getState();
  const workspace = workspaceSelectors.selectById(state, workspaceId);
  assertWorkspace(workspace, workspaceId);

  await deleteWorkspace(workspace.location);

  const { settings } = state;

  const wasSelectedWorkspaceDeleted =
    settings.selectedWorkspaceId === workspaceId;

  if (wasSelectedWorkspaceDeleted) {
    const remainingWorkspaces = workspaceSelectors
      .selectAll(state)
      .filter((ws) => ws.id !== workspaceId);

    const nextSelectedId = remainingWorkspaces.length
      ? remainingWorkspaces[0].id
      : undefined;

    await dispatch(
      saveDesignerSettingsAction({
        ...settings,
        selectedWorkspaceId: nextSelectedId,
      })
    ).unwrap();
  }

  return workspaceId;
});

/**
 * For current workspace only
 */
export const updateWorkspaceFolderAction = createAsyncThunk<
  { folderPath: WorkspaceFolder; files: FileNode[] },
  WorkspaceFolder,
  { state: RootState }
>("workspace/updateFolder", async (folderPath, { getState }) => {
  const workspace = selectSelectedWorkspace(getState());
  assertWorkspace(workspace);

  const folderContainsSubfolders = folderPath === "apis";
  const files = await updateFolder(
    workspace,
    folderPath,
    folderContainsSubfolders
  );

  return {
    files,
    folderPath,
  };
});

export const deleteLocalWorkspacesAction = createAsyncThunk(
  "workspace/deleteLocal",
  async (_, { getState }) => {
    const state = getState() as RootState;
    const workspaces = workspaceSelectors.selectAll(state);

    await Promise.all(
      workspaces.map((workspace) => deleteWorkspace(workspace.location))
    );
  }
);

export const importAndPushWorkspaceAction = createAsyncThunk<
  { errors: MigrationError[] },
  {
    sourceWorkspaceLocation: string;
    overwriteExisting: boolean;
  },
  { state: RootState }
>(
  "workspace/import",
  async (
    { sourceWorkspaceLocation, overwriteExisting },
    { getState, dispatch }
  ) => {
    const state = getState();

    const workspace = selectSelectedWorkspace(state);
    assertWorkspace(workspace);
    assertSettings(state.settings);

    const result = await importWorkspace(
      workspace.location,
      workspace.repositoryRoot,
      sourceWorkspaceLocation,
      overwriteExisting
    );

    const updatedWorkspace = await dispatch(
      refreshAndSetupWorkspaceAction()
    ).unwrap();

    await pushAllEntityChangesToGit(
      updatedWorkspace,
      state.settings.repositoryUrl,
      state.settings.repositoryToken,
      state.settings.repositoryRoot,
      overwriteExisting,
      state.settings.repositoryUsername
    );

    return {
      errors: result.errors,
    };
  }
);

export const pushEntitiesAndRefreshAction = createAsyncThunk<
  void,
  void,
  { state: RootState }
>("workspace/pushAndRefresh", async (_, { getState, dispatch }) => {
  const state = getState();

  const settings = state.settings;
  assertSettings(settings);

  const currentWorkspace = selectSelectedWorkspace(state);
  assertWorkspace(currentWorkspace);

  await pushAllEntityChangesToGit(
    currentWorkspace,
    settings.repositoryUrl,
    settings.repositoryToken,
    settings.repositoryRoot,
    false,
    settings.repositoryUsername
  );

  await dispatch(refreshAndSetupWorkspaceAction()).unwrap();
});

export type FileInfo = { file: FileNode; objectPath: string[] };
export const showEntityFileInWorkspaceAction = createAction<
  FileInfo & { entityFolder: WorkspaceFolder }
>(`workspace/showFile`);

export const hideEntityFileFromWorkspaceAction = createAsyncThunk<
  FileInfo,
  { id: string; entityFolder: WorkspaceFolder }
>(`workspace/hideFile`, ({ id, entityFolder }, { getState }) => {
  const path = getFileNameFromRegistry(id);
  if (!path) throw new Error("File does not exist");

  const ws = selectSelectedWorkspace(getState() as RootState);
  assertWorkspace(ws);

  const folder = ws[entityFolder];
  const objectPath = getObjectPathToFileNode(path, folder);
  const file = get(folder, objectPath);

  return { file, objectPath };
});
