import { REGX_FOR_VALID_STRING_EXPRESSION, Steps, StepSchema } from "../model";

export const restNewStepSchema: StepSchema = {
  stepType: Steps.REST_NEW,
  color: "rgb(247,225,211)",
  jsonSchema: {
    description: "REST API step definition",
    title: "REST Http",
    type: "object",
    properties: {
      name: {
        type: "string",
        title: "Name",
      },
      stepType: {
        type: "string",
        title: "stepType",
      },
      description: {
        type: "string",
        title: "Step Description",
      },
      condition: {
        type: "string",
        title:
          "An optional condition on the step. If returns false step will be skipped (e.g {{input.age>10}})",
        pattern: REGX_FOR_VALID_STRING_EXPRESSION,
      },
      config: {
        type: "object",
        title: "Configuration",
        required: ["endpoint", "targetPath"],
        properties: {
          endpoint: {
            type: "object",
            title: "End Point",
            required: ["url"],
            properties: {
              url: {
                type: "string",
                title: "Url - REST request url",
              },
              method: {
                type: "string",
                title: "Method - the rest method type (Default is GET)",
                enum: ["GET", "POST", "PUT", "DELETE", "PATCH"],
              },
            },
          },
          targetPath: {
            type: "string",
            title:
              "Target path - define the path in which to store the result returned when executing the api",
          },
          headerPath: {
            type: "string",
            title:
              "Define the path in which to store request/response headers of the api call. " +
              "No header values will be stored if left blank.",
          },
          cacheble: {
            type: "boolean",
            title: "Store target path results in cache",
          },
          responseTimeout: {
            type: "string",
            title:
              "Request start responding timeout (milliseconds) (Default is 10,000)",
          },
          deadlineTimeout: {
            type: "string",
            title:
              "Request finish responding timeout (milliseconds) (Default is 60,000)",
          },
          queryParams: {
            type: ["object", "string", "null"],
            title: "the query params to send in the rest request",
            pattern: REGX_FOR_VALID_STRING_EXPRESSION,
          },
          headers: {
            type: ["object", "null"],
            title: "The HTTP headers to send in the rest request",
          },
          restRequest: {
            title: "The body to send in the rest request",
            type: "string",
            enum: ["JSON", "XML", "Base64 file", "Text", "None"],
            default: "JSON",
          },
          expectedResponseLocation: {
            title:
              "The expected location of the response in the response object",
            type: "string",
            enum: ["body", "text"],
            default: "body",
          },
          oAuthConfig: {
            type: "object",
            title: "OAuth 2.0",
            properties: {
              oAuth: {
                type: "boolean",
                title: "Use OAuth 2.0 before sending the request",
              },
              oAuthAccessTokenUrl: {
                type: "string",
                title:
                  "Access Token URL (The endpoint for authentication server)",
              },
              oAuthClientId: {
                type: "string",
                title:
                  "Client ID - The client identifier issued to the client during the Application registration process",
              },
              oAuthClientSecret: {
                type: "string",
                title:
                  "Client Secret - The client secret issued to the client during the Application registration process",
              },
              oAuthScope: {
                type: "string",
                title: "Scope of the access request",
              },
            },
          },
          appendErrors: {
            type: "boolean",
            title: "Append errors to context?",
          },
        },
        dependencies: {
          cacheble: {
            oneOf: [
              {
                properties: {
                  cacheble: {
                    enum: [true],
                  },
                  disableHeadersInKey: {
                    type: "boolean",
                    title:
                      "Disable the usage of the HTTP headers in the cache key of the results",
                    enum: [true, false],
                    default: true,
                  },
                  cacheKey: {
                    type: "string",
                    title: "Cache Key",
                  },
                  cacheCleanupCondition: {
                    type: "string",
                    title: "Cache clean-up condition",
                    pattern: REGX_FOR_VALID_STRING_EXPRESSION,
                  },
                  ttl: {
                    type: "number",
                    title: "TTL cache (minutes)",
                  },
                },
              },
              {
                properties: {
                  cacheble: {
                    enum: [false],
                  },
                },
              },
            ],
          },
          restRequest: {
            oneOf: [
              {
                properties: {
                  restRequest: {
                    enum: ["JSON"],
                  },
                  json: {
                    type: ["object", "string", "null"],
                    title: "the JSON object to send to the method",
                    pattern: REGX_FOR_VALID_STRING_EXPRESSION,
                    default: {},
                  },
                },
              },
              {
                properties: {
                  restRequest: {
                    enum: ["XML"],
                  },
                  xml: {
                    type: ["string", "null"],
                    title: "the XML string to send to the method",
                  },
                },
              },
              {
                properties: {
                  restRequest: {
                    enum: ["Text"],
                  },
                  text: {
                    type: ["string", "null"],
                    title: "the text to send to the method",
                  },
                },
              },
              {
                properties: {
                  restRequest: {
                    enum: ["Base64 file"],
                  },
                  base64string: {
                    type: "string",
                    title: "The Base64 string to send to the method",
                  },
                  fieldName: {
                    type: "string",
                    title: "Field name in the form",
                  },
                  fileName: {
                    type: "string",
                    title: "Custom file name",
                  },
                },
              },
              {
                properties: {
                  restRequest: {
                    enum: ["None"],
                  },
                },
              },
            ],
          },
        },
      },
    },
  },
  uiSchema: {
    stepType: {
      "ui:widget": "hidden",
    },
    name: {
      classNames: "hidden-ace4",
    },
    description: {
      "ui:widget": "textarea",
    },
    config: {
      "ui:order": [
        "endpoint",
        "targetPath",
        "headerPath",
        "cacheble",
        "disableHeadersInKey",
        "cacheKey",
        "cacheCleanupCondition",
        "ttl",
        "*",
      ],
      json: {
        "ui:field": "json",
      },
      xml: {
        "ui:field": "xmlEditor",
      },
      base64string: {
        "ui:widget": "textarea",
      },
      text: {
        "ui:widget": "textarea",
      },
      queryParams: {
        "ui:field": "json",
      },
      headers: {
        "ui:field": "json",
      },
    },
  },
};
