import { PayloadAction } from "@reduxjs/toolkit";
import isUndefined from "lodash/isUndefined";
import YAML from "yaml";

import { Flow } from "../../model/flow";
import { Step } from "../../model/step";
import { serializeFlow } from "../../services/flows";
import { createRedoableSlice } from "../utils/redoableSliceFactory";
import {
  redoEntity,
  undoEntity,
  updateEntityWithDraft,
} from "../utils/redoableSliceMutators";
import { selectEntity } from "../utils/redoableSliceSelectors";
import { reorder } from "../utils/reorder";
import { closeFlowTabAction } from "../workspaces/tabActions";

import {
  deleteFlowAction,
  deleteFlowFolderAction,
  generateDataForInputSchemaAction,
  loadFlowAction,
  openFlowAction,
  overwriteFlowFolderAction,
  refreshFlowsAction,
  storeFlowAction,
} from "./actions";
import { name } from "./sliceName";

const flowsSlice = createRedoableSlice({
  name,
  reducers: {
    addStep(
      state,
      action: PayloadAction<{ flowId: string; step: Step; toIdx?: number }>
    ) {
      const { flowId, toIdx, step } = action.payload;
      const existingFlow = selectEntity<Flow>(state, flowId);

      if (existingFlow) {
        const { steps } = existingFlow.present;

        const existingStep = steps.find((st) => st.id === step.id);

        if (!existingStep) {
          const newSteps = [...steps];
          const start = isUndefined(toIdx) ? steps.length : toIdx;

          newSteps.splice(start, 0, step);

          const newEntityState = { ...existingFlow.present, steps: newSteps };
          const draftValue = YAML.stringify(serializeFlow(newEntityState));

          updateEntityWithDraft(state, newEntityState, draftValue);
        }
      }
    },
    updateStep(state, action: PayloadAction<{ flowId: string; step: Step }>) {
      const { flowId, step } = action.payload;
      const existingFlow = selectEntity<Flow>(state, flowId);

      if (existingFlow) {
        const { steps } = existingFlow.present;
        const updateIdx = steps.findIndex((st) => st.id === step.id);

        if (updateIdx !== -1) {
          const newSteps = [...steps];
          newSteps.splice(updateIdx, 1, step);

          const newEntityState = { ...existingFlow.present, steps: newSteps };
          const draftValue = YAML.stringify(serializeFlow(newEntityState));

          updateEntityWithDraft(state, newEntityState, draftValue);
        }
      }
    },
    removeStep(
      state,
      action: PayloadAction<{ flowId: string; stepId: string }>
    ) {
      const { stepId, flowId } = action.payload;
      const existingFlow = selectEntity<Flow>(state, flowId);

      if (existingFlow) {
        const { steps } = existingFlow.present;
        const removalIdx = steps.findIndex((step) => step.id === stepId);

        if (removalIdx !== -1) {
          const newSteps = [...steps];
          newSteps.splice(removalIdx, 1);

          const newEntityState = { ...existingFlow.present, steps: newSteps };
          const draftValue = YAML.stringify(serializeFlow(newEntityState));

          updateEntityWithDraft(state, newEntityState, draftValue);
        }
      }
    },
    reorderStepListItem(
      state,
      action: PayloadAction<{ flowId: string; fromIdx: number; toIdx: number }>
    ) {
      const { flowId, toIdx, fromIdx } = action.payload;
      const existingFlow = selectEntity<Flow>(state, flowId);

      if (existingFlow) {
        const steps = reorder(existingFlow.present.steps, fromIdx, toIdx);
        const newEntityState = { ...existingFlow.present, steps };
        const draftValue = YAML.stringify(serializeFlow(newEntityState));

        updateEntityWithDraft(state, newEntityState, draftValue);
      }
    },
    updateWithFlowDraft(state, action: PayloadAction<Flow>) {
      const flow = action.payload;
      const draftValue = YAML.stringify(serializeFlow(flow));

      return updateEntityWithDraft(state, flow, draftValue);
    },
    redoWithDraft(state, action: PayloadAction<string>) {
      const flowId = action.payload;

      redoEntity(state, flowId);
      const existingFlow = selectEntity<Flow>(state, flowId);

      if (existingFlow && existingFlow.draft) {
        existingFlow.draft = YAML.stringify(
          serializeFlow(existingFlow.present)
        );
      }
    },
    undoWithDraft(state, action: PayloadAction<string>) {
      const flowId = action.payload;

      undoEntity(state, flowId);
      const existingFlow = selectEntity<Flow>(state, flowId);

      if (existingFlow && existingFlow.draft) {
        existingFlow.draft = YAML.stringify(
          serializeFlow(existingFlow.present)
        );
      }
    },
  },
  predefinedThunks: {
    openEntityAction: openFlowAction,
    closeEntityTabAction: closeFlowTabAction,
    deleteEntityAction: deleteFlowAction,
    deleteFolderAction: deleteFlowFolderAction,
    overwriteFolderAction: overwriteFlowFolderAction,
    loadEntityAction: loadFlowAction,
    storeEntityAction: storeFlowAction,
    refreshEntitiesAction: refreshFlowsAction,
  },
  extraReducers: (builder) => {
    builder.addCase(
      generateDataForInputSchemaAction.fulfilled,
      (state, action) => {
        const flow = action.payload;
        const draftValue = YAML.stringify(serializeFlow(flow));

        return updateEntityWithDraft(state, flow, draftValue);
      }
    );
  },
});

export const {
  update,
  updateDraft,
  updateWithFlowDraft,
  updateOmitHistory,
  add,
  addTargetFolder,
  remove,
  redoWithDraft,
  undoWithDraft,
  addStep,
  updateStep,
  removeStep,
  reorderStepListItem,
  markDraftAsValid,
  markDraftAsInvalid,
  removeAll,
} = flowsSlice.actions;

export default flowsSlice.reducer;
