import { ExecuteFlowOptions } from "@sapiens-digital/ace-designer-app/app/model/flowExecution";
import { createAceHeaders } from "@sapiens-digital/ace-designer-common/lib/helpers/aceHeaders";
import { RepositoryDetails } from "@sapiens-digital/ace-designer-common/lib/model/git";

import { getTokenFromLocalStorage } from "../login/utils";

const postWithRepositoryDetails = async (
  path: string,
  repoDetails: RepositoryDetails,
  body: Record<string, unknown>
) => {
  const authorization = getTokenFromLocalStorage() || "";
  const options: RequestInit = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      ...createAceHeaders(repoDetails, authorization),
    } as HeadersInit,
    body: JSON.stringify(body),
  };

  try {
    const response = await fetch(
      process.env.REACT_APP_DEV_API_BASE_URL + path,
      options
    );

    if (response?.status === 401) {
      window.dispatchEvent(new CustomEvent("authfail"));
    }

    return await response.json();
  } catch (err) {
    console.error(err);
    return {
      status: "ERROR",
      error: "Some Error in connecting to Development server",
    };
  }
};

export const executeFlow = async (
  executeFlowOpts: ExecuteFlowOptions,
  deployReq: RepositoryDetails
): Promise<unknown> => {
  const flowRequest: Record<string, unknown> = { ...executeFlowOpts };
  if (typeof executeFlowOpts.flow === "string") {
    flowRequest.flowName = executeFlowOpts.flow;
    delete flowRequest.flow;
  }

  return postWithRepositoryDetails("/execute", deployReq, { flowRequest });
};
