import { createTheme } from "@mui/material";

declare module "@mui/material/styles/createPalette" {
  interface Palette {
    brand1: string;
    brand2: string;
    compliance: string;
    danger: string;
    white: string;
    primaryVariant: string;
    darkGrey: string;
  }
  interface PaletteOptions {
    brand1: string;
    brand2: string;
    compliance: string;
    danger: string;
    white: string;
    primaryVariant: string;
    darkGrey: string;
  }
}

const colors = {
  primary: {
    main: "#0065A3",
  },
  primaryVariant: "#004780",
  secondary: {
    main: "#00ADEF",
  },
  brand1: "#004780",
  brand2: "#00A7A7",
  success: {
    main: "#449D44",
  },
  info: {
    main: "#1C7CD5",
  },
  compliance: "#8A267D",
  warning: {
    main: "#EE9800",
  },
  danger: "#DC143C",
  white: "#FFFFFF",
  darkGrey: "#182330",
  grey: {
    100: "#F7FAFF",
    200: "#EFF2F7",
    300: "#E2E5Eb",
    400: "#BABFC7",
    500: "#9299A3",
    600: "#6A7380",
    700: "#424D5C",
    800: "#273444",
    900: "#182330",
  },
};

export const aceTheme = createTheme({
  palette: colors,
  components: {
    MuiListItemText: {
      styleOverrides: {
        primary: {
          height: 20,
          overflow: "hidden",
        },
        secondary: {
          height: 20,
          overflow: "hidden",
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          borderRadius: "3px !important",
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: `${colors.grey[500]} !important`,
          },
          "&.Mui-focused": {
            borderColor: `${colors.secondary.main} !important`,
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: `${colors.secondary.main} !important`,
          },
          "&.Mui-error .MuiOutlinedInput-notchedOutline": {
            borderColor: `${colors.danger} !important`,
          },
        },
      },
    },
  },
});

export const TOOL_BAR_BTN_WIDTH = 40;

export const TOOL_BAR_BTN_HEIGHT = 40;

export const TOOL_BAR_BUTTON_BORDER_RADIUS = {
  ROUNDED_ALL_CORNERS: "2px",
  ROUNDED_LEFT: "2px 0px 0px 2px",
  ROUNDED_RIGHT: "0px 2px 2px 0px",
  NOT_ROUNDED: "0px",
};

export type AceTheme = typeof aceTheme;
