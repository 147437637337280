import { StepSchema, Steps, REGX_FOR_VALID_STRING_EXPRESSION } from "../model";

export const switchStepSchema: StepSchema = {
  stepType: Steps.SWITCH,
  hideForAce4: true,
  jsonSchema: {
    description: "Switch step",
    title: "Switch",
    type: "object",
    properties: {
      name: {
        type: "string",
        title: "Name",
      },
      stepType: {
        type: "string",
        title: "stepType",
      },
      description: {
        type: "string",
        title: "Step Description",
      },
      config: {
        type: "object",
        title: "Configuration",
        properties: {
          switchExpression: {
            type: "string",
            title:
              "Expression to compare with switch conditions (e.g {{input.age}})",
            pattern: REGX_FOR_VALID_STRING_EXPRESSION,
          },
        },
      },
    },
  },
  uiSchema: {
    stepType: {
      "ui:widget": "hidden",
    },
    description: {
      "ui:widget": "textarea",
    },
  },
};
