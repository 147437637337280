export const HEADER_REPOSITORY = "ace-repository";
export const HEADER_TOKEN = "ace-token";
export const HEADER_BRANCH = "ace-branch";
export const HEADER_AUTHORIZATION = "ace-authorization";
export const HEADER_COMMIT = "ace-commit";
export const HEADER_USERNAME = "ace-username";
export const HEADER_AUTH_STATUS = "ace-auth-status";
export const HEADER_AUTH_STATUS_REJECTED = "rejected";
export const HEADER_ACCESS_CONTROL_EXPOSE_HEADERS =
  "Access-Control-Expose-Headers";

export interface AceHeaders {
  [HEADER_REPOSITORY]: string;
  [HEADER_TOKEN]: string;
  [HEADER_BRANCH]: string;
  [HEADER_AUTHORIZATION]: string;
  [HEADER_COMMIT]?: string;
  [HEADER_USERNAME]?: string;
  [HEADER_AUTH_STATUS]?: string;
}
