import { UN_VERSIONED_ENTITY_VAL } from "constant/uiConstants";
import { Api } from "model";
import { EntityPaths } from "store/schemas/selectors";
import { traverseObject } from "utils/traverseObject";

import { Schema, SerializedSchema } from "../model/schemas";
import { Workspace } from "../model/workspace";
import { nameSelector } from "../store/schemas/selectors";

import { removeUsageOfEntity } from "./indexing/indexer";
import { updateUsage } from "./indexing/updateUsage";
import { PlainObject } from "./workspace/migrate";
import { getVersionHistoryForEntity } from "./apisImport";
import {
  DeleteEntity,
  GetEntity,
  MoveEntity,
  SaveEntity,
} from "./entityService.types";
import {
  deleteFile,
  getFileDisplayName,
  moveFile,
  readFile,
  saveFile,
} from "./fs-utils";
import { getDeserializedId } from "./references";
import { getContentRoot } from "./workspace";

export const getSchema: GetEntity<Schema> = async (id, workspace) => {
  try {
    const file = (await readFile(id, workspace, "schemas")) as SerializedSchema;
    const fileName = getFileDisplayName(id, workspace, "schemas");
    return deserializeSchema(id, fileName, file, workspace);
  } catch (e) {
    console.error(e);
    throw new Error(`Schema with the id "${id}" can not be retrieved`);
  }
};

export const getSchemaId = async (
  path: string,
  workspace: Workspace
): Promise<string> => {
  const id = getDeserializedId(getContentRoot(workspace, "schemas"), path);

  if (id === undefined) {
    throw new Error(`Schema with the path "${path}" can not be retrieved`);
  }

  return id;
};

export const getMaxVersionedSchema = async (
  schemaName: string,
  existingApiFile: Api,
  existingPaths: EntityPaths,
  workspace: Workspace
): Promise<{ schema: SerializedSchema | undefined; maxVersion: number }> => {
  const { maxVersion } = getVersionHistoryForEntity(existingPaths, schemaName);

  let versionedSchemaName = schemaName;

  if (maxVersion !== UN_VERSIONED_ENTITY_VAL) {
    versionedSchemaName = `${schemaName}V${maxVersion}`;
  }

  let schema;

  if (existingApiFile.components?.schemas?.[versionedSchemaName]) {
    schema = existingApiFile.components.schemas[versionedSchemaName];
  }

  if (!schema) {
    try {
      const entityId = await getSchemaId(
        versionedSchemaName + ".yaml",
        workspace
      );

      if (entityId) {
        const { file: schemaFromFile } = await getSchema(entityId, workspace);
        schema = schemaFromFile;
      }
    } catch (e) {
      console.warn("Cannot read schema from workspace", e);
    }
  }

  return {
    maxVersion,
    schema,
  };
};

export const saveSchema: SaveEntity<Schema> = async (
  schema,
  workspace,
  targetPath
) => {
  const name = nameSelector(schema);

  try {
    const serialized = serializeSchema(schema, workspace);

    await saveFile(
      schema.id,
      workspace,
      "schemas",
      serialized,
      name,
      targetPath
    );

    return schema;
  } catch (e) {
    console.error(e);
    throw new Error(`Schema "${name}" can not be saved`);
  }
};

export const moveSchema: MoveEntity<Schema> = async (
  id,
  workspace,
  targetPath
) => {
  try {
    await moveFile(id, workspace, "schemas", targetPath);
  } catch (e) {
    console.error(e);
    throw new Error(`Schema with the id "${id}" can not be moved`);
  }
};

export const deleteSchema: DeleteEntity = async (id, workspace) => {
  try {
    await deleteFile(id, workspace, "schemas");
    removeUsageOfEntity(id, "schemas");
  } catch (e) {
    console.error(e);
    throw new Error(`Schema with the id "${id}" can not be deleted`);
  }
};

export const deserializeSchema = (
  id: string,
  name: string,
  file: SerializedSchema,
  workspace?: Workspace
): Schema => {
  const result = { id, fileName: name, file };

  workspace && updateSchemaUsage(result, workspace);

  return result;
};

export const serializeSchema = (
  schema: Schema,
  workspace?: Workspace
): SerializedSchema => {
  const { file } = schema;
  workspace && updateSchemaUsage(schema, workspace);
  return file;
};

export const updateSchemaUsage = (
  { file, id }: Pick<Schema, "file" | "id">,
  workspace: Workspace
): void => {
  const schemas: string[] = [];

  traverseObject(file as PlainObject, (_, value) => {
    if (!(typeof value === "string" && value.endsWith(".yaml"))) {
      return;
    }

    value && schemas.push(value);
  });

  updateUsage({ id, entityType: "schemas" }, { schemas }, workspace);
};

export const initSchemaReferences = async (
  ids: string[],
  workspace: Workspace
): Promise<void> => {
  for (const id of ids) {
    await getSchema(id, workspace);
  }
};
