import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import { Box, IconButton, Paper } from "@mui/material";
import {
  AppBar,
  Button,
  Dialog,
  DialogContent,
  Slide,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions/transition";

import { RUNTIME_SERVER_DEFAULT_PORT } from "../constant/serverConfig";
import { Settings } from "../model/settings";
import {
  validateDefaultBranch,
  validateRepositoryAccess,
  validateWorkspacesLocation,
} from "../services/settings";
import { Environment, getEnvironment } from "../services/workspace";
import { RootState, useAppDispatch } from "../store";
import { initializeDesignerAction } from "../store/designer/actions";
import { closeSettings } from "../store/designer/reducers";
import { saveDesignerSettingsAction } from "../store/settings/actions";

import { canParseToNumber, isEmpty, isUrlValid } from "./validators/settings";

const rootStyle = { margin: "4px", padding: "4px" };

const TransitionComponent = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DesignerSettings: React.FC = () => {
  const isElectron = getEnvironment() === Environment.Electron;

  const open = useSelector<RootState, boolean>(
    (state) => state.designer.settingsOpen
  );
  const dispatch = useAppDispatch();

  const settings = useSelector<RootState, Settings>((state) => state.settings);

  const [workspacesLocation, setWorkspacesLocation] = useState(
    settings.workspacesLocation || ""
  );
  const [repositoryUrl, setRepositoryUrl] = useState(
    settings.repositoryUrl || ""
  );
  const [repositoryUsername, setRepositoryUsername] = useState(
    settings.repositoryUsername || ""
  );
  const [repositoryToken, setRepositoryToken] = useState(
    settings.repositoryToken || ""
  );
  const [repositoryDefaultBranch, setRepositoryDefaultBranch] = useState(
    settings.repositoryDefaultBranch || ""
  );
  const [runtimeServerPort, setRuntimeServerPort] = useState(
    String(settings.runtimeServerPort || RUNTIME_SERVER_DEFAULT_PORT)
  );

  const [
    workspacesLocationHelperText,
    setWorkspacesLocationHelperText,
  ] = useState("");
  const [repositoryUrlHelperText, setRepositoryUrlHelperText] = useState("");
  const [repositoryTokenHelperText, setRepositoryTokenHelperText] = useState(
    ""
  );
  const [
    repositoryDefaultBranchHelperText,
    setRepositoryDefaultBranchHelperText,
  ] = useState("");
  const [
    runtimeServerPortHelperText,
    setRuntimeServerPortHelperText,
  ] = useState("");

  const [workspacesLocationError, setWorkspacesLocationError] = useState(false);
  const [repositoryUrlError, setRepositoryUrlError] = useState(false);
  const [repositoryTokenError, setRepositoryTokenError] = useState(false);
  const [
    repositoryDefaultBranchError,
    setRepositoryDefaultBranchError,
  ] = useState(false);
  const [runtimeServerPortError, setRuntimeServerPortError] = useState(false);

  const firstRender = useRef(true);

  // Update state when settings change in Application state or dialog is opened
  useEffect(() => {
    setWorkspacesLocation(settings.workspacesLocation || "");
    setRuntimeServerPort(
      String(settings.runtimeServerPort || RUNTIME_SERVER_DEFAULT_PORT)
    );
    setRepositoryUrl(settings.repositoryUrl || "");
    setRepositoryUsername(settings.repositoryUsername || "");
    setRepositoryToken(settings.repositoryToken || "");
    setRepositoryDefaultBranch(settings.repositoryDefaultBranch || "");
  }, [settings, open]);

  // UI field basic validation
  useEffect(() => {
    if (firstRender.current === true) {
      return;
    }

    isEmpty(
      workspacesLocation,
      setWorkspacesLocationHelperText,
      setWorkspacesLocationError
    );
  }, [workspacesLocation]);

  useEffect(() => {
    if (firstRender.current === true) {
      return;
    }

    isEmpty(repositoryUrl, setRepositoryUrlHelperText, setRepositoryUrlError);
    isUrlValid(
      repositoryUrl,
      setRepositoryUrlHelperText,
      setRepositoryUrlError
    );
  }, [repositoryUrl]);

  useEffect(() => {
    if (firstRender.current === true) {
      return;
    }

    isEmpty(
      repositoryToken,
      setRepositoryTokenHelperText,
      setRepositoryTokenError
    );
  }, [repositoryToken]);

  useEffect(() => {
    if (firstRender.current === true) {
      return;
    }

    isEmpty(
      repositoryDefaultBranch,
      setRepositoryDefaultBranchHelperText,
      setRepositoryDefaultBranchError
    );
  }, [repositoryDefaultBranch]);

  useEffect(() => {
    if (firstRender.current === true) {
      return;
    }

    canParseToNumber(
      runtimeServerPort,
      setRuntimeServerPortHelperText,
      setRuntimeServerPortError
    );
  }, [runtimeServerPort]);

  useEffect(() => {
    firstRender.current = false;
  });

  const onSave = async () => {
    // All fields has to be validated regardless if any of them is valid or invalid
    let notValid = false;

    if (isElectron) {
      notValid =
        canParseToNumber(
          runtimeServerPort,
          setRuntimeServerPortHelperText,
          setRuntimeServerPortError
        ) || notValid;

      notValid =
        isEmpty(
          workspacesLocation,
          setWorkspacesLocationHelperText,
          setWorkspacesLocationError
        ) || notValid;
    }

    notValid =
      isEmpty(
        repositoryUrl,
        setRepositoryUrlHelperText,
        setRepositoryUrlError
      ) || notValid;
    notValid =
      isEmpty(
        repositoryToken,
        setRepositoryTokenHelperText,
        setRepositoryTokenError
      ) || notValid;
    notValid =
      isEmpty(
        repositoryDefaultBranch,
        setRepositoryDefaultBranchHelperText,
        setRepositoryDefaultBranchError
      ) || notValid;
    notValid =
      isUrlValid(
        repositoryUrl,
        setRepositoryUrlHelperText,
        setRepositoryUrlError
      ) || notValid;

    if (notValid) {
      return;
    }

    if (isElectron) {
      const locationResult = await validateWorkspacesLocation(
        workspacesLocation
      );

      if (locationResult.valid === false) {
        setWorkspacesLocationHelperText(
          locationResult.message ?? "Failed to validate provided location."
        );
        setWorkspacesLocationError(true);
        return;
      }
    }

    const repositoryResult = await validateRepositoryAccess(
      repositoryUrl,
      repositoryToken,
      repositoryUsername
    );

    if (repositoryResult.valid === false) {
      setRepositoryUrlHelperText(
        repositoryResult.message ?? "Failed to validate repository."
      );
      setRepositoryUrlError(true);
      return;
    }

    const branchResult = await validateDefaultBranch(
      repositoryUrl,
      repositoryToken,
      repositoryDefaultBranch,
      repositoryUsername
    );

    if (branchResult.valid === false) {
      setRepositoryDefaultBranchHelperText(
        branchResult.message ?? "Failed to validate default branch."
      );
      setRepositoryDefaultBranchError(true);
      return;
    }

    const result = await dispatch(
      saveDesignerSettingsAction({
        ...settings,
        workspacesLocation,
        repositoryUrl,
        repositoryUsername,
        repositoryToken,
        repositoryDefaultBranch,
        runtimeServerPort: parseInt(runtimeServerPort),
      })
    ).unwrap();
    dispatch(closeSettings());

    if (!result.selectedWorkspaceId) {
      dispatch(initializeDesignerAction());
    }
  };

  const handleClose = () => {
    dispatch(closeSettings());
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullScreen
      TransitionProps={{ mountOnEnter: true, unmountOnExit: true }}
      TransitionComponent={TransitionComponent}
    >
      <AppBar style={{ position: "relative" }}>
        <Toolbar>
          <IconButton
            onClick={() => {
              dispatch(closeSettings());
            }}
            color="inherit"
            data-testid="settings-close-icon"
          >
            <CloseIcon />
          </IconButton>

          <Typography style={{ flex: 1 }}>Settings</Typography>
          <Button color="inherit" onClick={onSave}>
            Save
          </Button>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <Paper>
          <Box m={2} p={2}>
            {isElectron && (
              <TextField
                variant="standard"
                sx={rootStyle}
                fullWidth
                label="Location of ACE workspaces (applied after restart)"
                value={workspacesLocation}
                onChange={(event) => setWorkspacesLocation(event.target.value)}
                helperText={workspacesLocationHelperText}
                error={workspacesLocationError}
              />
            )}
            <TextField
              variant="standard"
              sx={rootStyle}
              fullWidth
              label="Repository URL"
              value={repositoryUrl}
              onChange={(event) => setRepositoryUrl(event.target.value)}
              helperText={repositoryUrlHelperText}
              error={repositoryUrlError}
            />
            <TextField
              variant="standard"
              sx={rootStyle}
              fullWidth
              label="Repository Username"
              value={repositoryUsername}
              onChange={(event) => setRepositoryUsername(event.target.value)}
            />
            <TextField
              variant="standard"
              sx={rootStyle}
              fullWidth
              label="Repository token"
              type="password"
              value={repositoryToken}
              onChange={(event) => setRepositoryToken(event.target.value)}
              helperText={repositoryTokenHelperText}
              error={repositoryTokenError}
            />
            <TextField
              variant="standard"
              sx={rootStyle}
              fullWidth
              label="Repository default branch"
              value={repositoryDefaultBranch}
              onChange={(event) =>
                setRepositoryDefaultBranch(event.target.value)
              }
              helperText={repositoryDefaultBranchHelperText}
              error={repositoryDefaultBranchError}
            />
            {isElectron && (
              <TextField
                variant="standard"
                sx={rootStyle}
                fullWidth
                label="Runtime server port (applied after restart)"
                value={runtimeServerPort || ""}
                onChange={(event) => setRuntimeServerPort(event.target.value)}
                helperText={runtimeServerPortHelperText}
                error={runtimeServerPortError}
              />
            )}
          </Box>
        </Paper>
      </DialogContent>
    </Dialog>
  );
};

export default DesignerSettings;
