import { mapObject, REF_PREFIX } from "@sapiens-digital/ace-designer-common";
import posixPath from "@sapiens-digital/ace-designer-common/lib/helpers/posixPath";
import { SerializedApi } from "model";

import { PlainObject } from "./migrate";

type RefArray = string[];

export function getRefName(key: string, value: unknown): string | undefined {
  if (
    key === "$ref" &&
    typeof value === "string" &&
    value.startsWith(REF_PREFIX)
  ) {
    return value.slice(REF_PREFIX.length);
  }

  return undefined;
}

export const updateDeprecatedRefFormatInSchema = (
  schema: unknown,
  pathToSchemasDirectory: string,
  onlyUpdateSchemasWithName?: RefArray
): unknown =>
  mapObject(schema, (key, value) => {
    if (key !== "$ref") return value;

    const schemaName = getRefName(key, value);

    if (schemaName) {
      if (
        onlyUpdateSchemasWithName &&
        !onlyUpdateSchemasWithName.includes(schemaName)
      ) {
        return value;
      }

      return posixPath.join(pathToSchemasDirectory, `${schemaName}.yaml`);
    }

    return value;
  });

export const extractSchemaNames = (obj: Record<string, unknown>): string[] => {
  const result: string[] = [];

  mapObject(obj, (key, value) => {
    if (key !== "$ref") return value;

    const schemaName = getRefName(key, value);

    if (schemaName) {
      result.push(schemaName);
    }

    return value;
  });

  return result;
};

export const extractUniqueSchemaNames = (obj: PlainObject): string[] => {
  const schemaNames = extractSchemaNames(obj);
  return Array.from(new Set(schemaNames));
};

export const renameSchemasInObj = (
  obj: SerializedApi | Record<string, unknown>,
  renameMapping: Record<string, string>
): SerializedApi | Record<string, unknown> =>
  mapObject(obj, (key, value) => {
    if (key !== "$ref") return value;

    const schemaName = getRefName(key, value);

    if (schemaName && renameMapping[schemaName]) {
      return posixPath.join(REF_PREFIX, renameMapping[schemaName]);
    }

    return value;
  });

const versionedSchemaNameMatchRegEx = new RegExp("\\w+Vd*", "g");

export const removeVersionSuffix = (name: string): string | undefined => {
  const matchData = name.match(versionedSchemaNameMatchRegEx);

  if (matchData && matchData[0]) {
    return matchData[0].slice(0, -1);
  }
};

export const removeVersionFromSchemasNames = (
  obj: SerializedApi | Record<string, unknown>
): SerializedApi | Record<string, unknown> =>
  mapObject(obj, (key, value) => {
    if (key !== "$ref") return value;

    const schemaName = getRefName(key, value);

    if (schemaName) {
      const schemaNameWithoutVersion = removeVersionSuffix(schemaName);

      if (schemaNameWithoutVersion) {
        return posixPath.join(REF_PREFIX, schemaNameWithoutVersion);
      }
    }

    return value;
  });
