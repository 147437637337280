import { useCallback, useEffect, useState } from "react";

import { getTimeRemainingToExpire } from "./JWTTokenUtils";
import {
  clearTokenFromLocalStore,
  getTokenFromLocalStorage,
  isKeycloakAuthenticationEnabled,
} from "./utils";

/**
 * Hook to get the Auth token from local storage and listen for token changes
 */
export const useToken = (): {
  token: string | null;
  clearToken: () => void;
} => {
  const [token, setToken] = useState(getTokenFromLocalStorage());

  const clearToken = useCallback(() => {
    setToken(null);
    clearTokenFromLocalStore();
  }, []);

  const onStorageChange = useCallback(
    (event: StorageEvent): void => {
      if (event.key === "Authorization") {
        setToken(event.newValue);

        if (isKeycloakAuthenticationEnabled()) {
          const ttl = getTimeRemainingToExpire(event.newValue);
          if (ttl && ttl > 0) {
            setTimeout(() => {
              clearToken();
            }, ttl);
          } else if (ttl && ttl < 0) {
            clearToken();
          }
        }
      }
    },
    [clearToken]
  );

  useEffect(() => {
    window.addEventListener("storage", onStorageChange);
    return () => {
      window.removeEventListener("storage", onStorageChange);
    };
  }, [onStorageChange]);

  return { token, clearToken };
};
