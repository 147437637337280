import React from "react";
import { Designer } from "@sapiens-digital/ace-designer-app/app/components/Designer";

import logo from "./assets/logo.png";
import { LoginPopup } from "./login/LoginPopup";
import { LoginTracker } from "./login/LoginTracker";

interface DesignerAppProps {
  showLoginPopup: boolean;
}

function DesignerApp(props: DesignerAppProps): React.ReactElement {
  return (
    <React.Fragment>
      <LoginTracker />
      <LoginPopup open={props.showLoginPopup} />
      <Designer logo={logo} />
    </React.Fragment>
  );
}

export default DesignerApp;
