import { PlainObject } from "services/workspace/migrate";

export type OnTraverseProperty = (key: string, value: unknown) => void;

export function traverseObject(
  obj: unknown,
  onProperty: OnTraverseProperty
): void {
  if (!(typeof obj === "object" && obj !== null)) {
    return;
  }

  for (const key in obj) {
    const val = (obj as PlainObject)[key];
    onProperty(key, val);
    traverseObject(val, onProperty);
  }
}
