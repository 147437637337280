import { createAsyncThunk } from "@reduxjs/toolkit";
import { v4 as uuid4 } from "uuid";

import { selectApiTreeEntity } from "../designer/actions";
import { RootState } from "../index";
import { selectFolder } from "../workspaces/selectors";

import { loadWorkspaceApisIntoState } from "./actions";
import { add } from "./reducers";
import { name } from "./sliceName";

export const baseOpenApiSchema = {
  openapi: "3.0.0",
  info: {
    version: "1.0.0",
    title: "Dynamic apis",
    description: "List of dynamic apis",
  },
  paths: {},
  components: {
    schemas: {},
  },
};

export const initializeApisAction = createAsyncThunk(
  `${name}/initialize`,
  async (_, { dispatch, getState }): Promise<void> => {
    const state = getState() as RootState;
    const apisFolder = selectFolder(state, "apis");

    if (apisFolder.length === 0) {
      const id = uuid4();
      dispatch(add({ id: id, name: "ace", ...baseOpenApiSchema }));
      dispatch(selectApiTreeEntity(id));
      return;
    }

    if (apisFolder.length === 1) {
      dispatch(selectApiTreeEntity(apisFolder[0].id as string));
    }

    await dispatch(loadWorkspaceApisIntoState());
  }
);
