import { generateSchemaFromJson } from "@sapiens-digital/ace-designer-common/lib/helpers/generateSchemaFromJson";
import { Logger } from "@sapiens-digital/ace-designer-common/lib/logging";
import { SerializedApi } from "@sapiens-digital/ace-designer-common/lib/model/api";
import * as workspacePaths from "@sapiens-digital/ace-designer-common/lib/model/workspacePaths";
import _ from "lodash";
import { OpenAPIV3 } from "openapi-types";
import path from "path";

import { createEmptyApi } from "../../utils/factory";
import { createSortYamlMapEntries } from "../../utils/sortYaml";
import { readYaml, YAMLStringifyOptions } from "../fs-utils";

import { V1EntityMigrator } from "./migrate";
import { saveAsYaml } from "./migrateUtils";
import { updateDeprecatedRefFormatInSchema } from "./schemaReferences";

/**
 * ACE 4 file structure
 */
export type DynamicApi = {
  path: string;
  verb: string;
  tag?: string;
  summary?: string;
  responseDescription?: string;
  flow?: string;
  dataModel?: OpenAPIV3.ParameterObject[];
  secured?: boolean;
  responseSchema?: OpenAPIV3.ResponseObject; //TODO: response example
  errorTags?: string[];
  requestBody?: OpenAPIV3.RequestBodyObject;
  requestExample?: OpenAPIV3.ExampleObject; //TODO: request example
  responses?: OpenAPIV3.ResponsesObject;
  operationId?: string;
  version?: number; //ignored
};

const OPTIONS = ["components", "info", "openapi", "paths", ""];
const YAML_OPTIONS: YAMLStringifyOptions = {
  sortMapEntries: createSortYamlMapEntries(OPTIONS),
};

const migrateApi: V1EntityMigrator = async ({
  content,
  fileBasename,
  targetRepositoryLocation,
  repositoryLocation,
  overwriteExisting,
}) => {
  const dynamicApis = content as DynamicApi[];
  const sourceFile = await readApiFile(repositoryLocation as string);

  if (overwriteExisting) {
    sourceFile.paths = {};
  }

  for (const dynamicApi of dynamicApis) {
    const verb = dynamicApi.verb.toLowerCase() as OpenAPIV3.HttpMethods;
    const operation = sourceFile.paths[dynamicApi.path] || {};
    operation[verb] = {
      "x-ace-flow": dynamicApi.flow + ".yaml",
      "x-ace-error-handlers": dynamicApi.errorTags ? dynamicApi.errorTags : [],
      "x-ace-secured": dynamicApi.secured,
      responses: mapResponses(dynamicApi),
      operationId: dynamicApi.operationId,
      ...(Array.isArray(dynamicApi.dataModel) && {
        parameters: dynamicApi.dataModel,
      }),
      ...(dynamicApi.summary && { summary: dynamicApi.summary }),
      ...(dynamicApi.tag && { tags: [dynamicApi.tag] }),
      requestBody: getBodyParam(dynamicApi),
    };
    sourceFile.paths[dynamicApi.path] = updateDeprecatedRefFormatInSchema(
      operation,
      workspacePaths.WORKSPACE_SCHEMAS
    ) as typeof operation;
  }

  await saveAsYaml(
    targetRepositoryLocation,
    workspacePaths.WORKSPACE_APIS,
    "ace",
    sourceFile,
    YAML_OPTIONS
  );

  return [];
};

function mapResponses(dynamicApi: DynamicApi): OpenAPIV3.ResponsesObject {
  if (dynamicApi.responseSchema) {
    return {
      200: {
        description: dynamicApi.responseDescription || "",
        content: {
          "application/json": {
            schema:
              dynamicApi.responseSchema &&
              generateSchemaFromJson(dynamicApi.responseSchema),
          },
        },
      },
    };
  }

  return dynamicApi.responses ? dynamicApi.responses : {};
}

function getBodyParam(api: DynamicApi): OpenAPIV3.RequestBodyObject {
  let requestBody = null;
  let apiRequestExample = api.requestExample;

  /* This need for backward compitability */
  if (apiHasBody(api.verb) && api.dataModel) {
    const match = JSON.stringify(api.dataModel).match(
      /(?=.*in)(?=.*path)|(?=.*in)(?=.*query)|(?=.*in)(?=.*header)/g
    );

    const isEmptyDataModel = checkDataModel(api.dataModel);

    if (!match && !isEmptyDataModel) {
      apiRequestExample = api.dataModel as OpenAPIV3.ExampleObject;
    }
  }

  if (api.requestExample) {
    requestBody = {
      description: "body param generated from provided JSON request example",
      required: true,
      content: {
        "application/json": {
          schema: generateSchemaFromJson(apiRequestExample),
        },
      },
    };
  }

  if (api.requestBody) {
    requestBody = api.requestBody;
  }

  return requestBody as OpenAPIV3.RequestBodyObject;
}

async function readApiFile(repositoryLocation: string): Promise<SerializedApi> {
  try {
    return (await readYaml(
      path.join(repositoryLocation, workspacePaths.WORKSPACE_APIS, "ace.yaml")
    )) as SerializedApi;
  } catch {
    Logger.getLogger("migrateApi").debug("Creating new ace.yaml file.");
    return createEmptyApi();
  }
}

function apiHasBody(verb: string): boolean {
  return verb.toUpperCase() === "POST" || verb.toUpperCase() === "PUT";
}

function checkDataModel(dataModel: OpenAPIV3.ParameterObject[]): boolean {
  if (Array.isArray(dataModel) && dataModel.length === 0) {
    return true;
  }

  if (_.isObject(dataModel)) {
    return true;
  }

  return false;
}

export default migrateApi;
