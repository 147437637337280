import { Steps, StepSchema } from "../model";
import { ajvStepSchema } from "./ajvStepSchema";
import { cleanObjectStepSchema } from "./cleanObjectStepSchema";
import { catchStepSchema } from "./catchStepSchema";
import { codeStepSchema } from "./codeStepSchema";
import { dateStepSchema } from "./dateStepSchema";
import { deepMergeStepSchema } from "./deepMergeStepSchema";
import { inputTransformStepSchema } from "./inputTransformStepSchema";
import { jsonRpcStepSchema } from "./jsonRpcStepSchema";
import { jsonataStepSchema } from "./jsonataStepSchema";
import { jwtStepSchema } from "./jwtStepSchema";
import { logStepSchema } from "./logStepSchema";
import { lookupStepSchema } from "./lookupStepSchema";
import { mapStepSchema } from "./mapStepSchema";
import { mixedStepSchema } from "./mixedStepSchema";
import { mongodbStepSchema } from "./mongodbStepSchema";
import { multiflowStepSchema } from "./multiflowStepSchema";
import { oracledbStepSchema } from "./oracledbStepSchema";
import { respValidationStepSchema } from "./respValidationStepSchema";
import { reqValidationStepSchema } from "./reqValidationStepSchema";
import { restDeprecatedStepSchema } from "./restDeprecatedStepSchema";
import { restNewStepSchema } from "./restNewStepSchema";
import { sapiqDbStepSchema } from "./sapiqDbStepSchema";
import { SoapStepSchema } from "./soapStepSchema";
import { SoapDeprecatedStepSchema } from "./soapDeprecatedStepSchema";
import { SoapPureStepSchema } from "./soapPureStepSchema";
import { transformStepSchema } from "./transformStepSchema";
import { xmlStepSchema } from "./xmlStepSchema";
import { redisStepSchema } from "./redisStepSchema";
import { mssqlDBStepSchema } from "./mssqldbStepSchema";
import { ibmdbStepSchema } from "./ibmdbStepSchema";
import { clearCacheStepSchema } from "./clearCacheStepSchema";
import { stopStepSchema } from "./stopStepSchema";
import { switchStepSchema } from "./switchStepSchema";

export const stepsSchemasList: StepSchema[] = [
  ajvStepSchema,
  catchStepSchema,
  cleanObjectStepSchema,
  codeStepSchema,
  dateStepSchema,
  deepMergeStepSchema,
  inputTransformStepSchema,
  jsonRpcStepSchema,
  jsonataStepSchema,
  jwtStepSchema,
  logStepSchema,
  lookupStepSchema,
  mapStepSchema,
  mixedStepSchema,
  mongodbStepSchema,
  multiflowStepSchema,
  oracledbStepSchema,
  respValidationStepSchema,
  restDeprecatedStepSchema,
  restNewStepSchema,
  sapiqDbStepSchema,
  SoapStepSchema,
  SoapDeprecatedStepSchema,
  SoapPureStepSchema,
  transformStepSchema,
  xmlStepSchema,
  redisStepSchema,
  ibmdbStepSchema,
  mssqlDBStepSchema,
  clearCacheStepSchema,
  reqValidationStepSchema,
  stopStepSchema,
  switchStepSchema,
];

export type StepTypeGroup = {
  id: string;
  title: string;
  groupColor: string;
  children: Steps[];
};

export const stepTypeGroups: StepTypeGroup[] = [
  {
    id: "data-transform",
    title: "Data transformation",
    groupColor: "#259b24",
    children: [
      Steps.DEEP_MERGE,
      Steps.INPUT_TRANSFORM,
      Steps.JSONATA,
      Steps.LOOKUP,
      Steps.CLEAN_OBJECT,
      Steps.MAP,
      Steps.TRANSFORM,
      Steps.XML,
    ],
  },
  {
    id: "network-req",
    title: "Network Requests",
    groupColor: "#3f51b5",
    children: [
      Steps.JSON_RPC,
      Steps.REST,
      Steps.REST_NEW,
      Steps.SOAP,
      Steps.SOAP_NEW,
      Steps.SOAP_PURE,
      Steps.JWT,
    ],
  },
  {
    id: "database-access",
    title: "Database Access",
    groupColor: "#9c27b0",
    children: [
      Steps.MONGODB,
      Steps.ORACLEDB,
      Steps.SAPIQDB,
      Steps.REDIS,
      Steps.IBM_DB,
      Steps.MSSQL_DB,
    ],
  },
  {
    id: "data-validation",
    title: "Data Validation",
    groupColor: "#795548",
    children: [Steps.RESPONSE_VALIDATION, Steps.AJV, Steps.REQUEST_VALIDATION],
  },
  {
    id: "utility",
    title: "Utility",
    groupColor: "#607d8b",
    children: [
      Steps.DATE,
      Steps.CODE,
      Steps.LOG,
      Steps.MIXEDFLOW,
      Steps.MULTIFLOW,
      Steps.CATCH,
      Steps.CLEAR_CACHE,
    ],
  },
  {
    id: "logic",
    title: "Logic",
    groupColor: "#FFEA00",
    children: [Steps.STOP_STEP, Steps.SWITCH],
  },
];

export const deprecatedSteps: string[] = [
  Steps.MULTIFLOW,
  Steps.REST,
  Steps.SOAP,
];
