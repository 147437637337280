import { ToggableStateNames } from "../designer/mutator";
import { RootState } from "..";

export const finishInitializeLoading = (state: RootState): void => {
  state.designer.isDesignerLoading = false;
};

export const expandEntityFolder = (
  state: RootState,
  entityId: string,
  stateName: ToggableStateNames
): void => {
  if (!state.designer[stateName].includes(entityId)) {
    state.designer[stateName] = [...state.designer[stateName], entityId];
  }
};
