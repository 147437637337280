import { createSelector } from "@reduxjs/toolkit";
import { GenericState } from "store/utils/redoableSliceFactory";

import { sortAscInsensitive } from "../../components/utils/filterAndSort";
import { FileNode, FileType, Folder } from "../../model/file";
import { Schema } from "../../model/schemas";
import { findNode, getAllChildNodes } from "../../services/nodes";
import { ALL_NODES_ID, ROOT_NODE_ID } from "../designer/constants";
import { getEntityTargetFolderPath } from "../utils/getEntityTargetPath";
import { omitExtension } from "../utils/path";
import { selectEntity } from "../utils/redoableSliceSelectors";
import { selectDropdownOptions } from "../utils/selectors/selectDropdownOptions";
import { selectFolder, selectNodes } from "../workspaces/selectors";
import { RootState } from "..";

export const selectSchemasFolder = (state: RootState): Folder =>
  selectFolder(state, "schemas");

export const selectSchemasNamesWithExt = createSelector(
  selectSchemasFolder,
  (schemasFolder) => schemasFolder.map((v) => `${v.displayName}.yaml`)
);

export const nameSelector = (schema: Schema): string => schema.fileName;

export const selectSchemaPresent = (
  state: RootState,
  schemaId: string
): Schema | undefined => selectEntity(state.schemas, schemaId)?.present;

export const selectSchemaListItems = createSelector(
  (state: RootState) => selectSchemasFolder(state),
  (state: RootState) => state.designer.selectedSchemasFolderId,
  (schemaFolder, selectedSchemasFolderId) => {
    if (
      selectedSchemasFolderId === undefined ||
      selectedSchemasFolderId === ALL_NODES_ID
    ) {
      return schemaFolder.reduce<Array<FileNode>>((result, node) => {
        if (node.type === FileType.File) {
          return [...result, node];
        }

        return [...result, ...getAllChildNodes(node, true)];
      }, []);
    }

    if (selectedSchemasFolderId === ROOT_NODE_ID) {
      return schemaFolder.filter((node) => node.type === FileType.File);
    }

    const node = findNode(selectedSchemasFolderId, schemaFolder);

    if (node) {
      return node.children.filter((node) => node.type === FileType.File);
    }

    return [];
  }
);

export const selectSelectedSchemaId = (state: RootState): string =>
  state.designer.selectedSchemaId;

export type EntityPaths = Record<string, string>;

export const selectSchemaPaths = createSelector(
  (state: RootState) => selectNodes(state, "schemas", ALL_NODES_ID),
  (nodes) =>
    nodes.reduce<EntityPaths>(
      (before, { id, path }) => ({ ...before, [id]: omitExtension(path) }),
      {}
    )
);

export const selectSortedSchemaDropdownOptions = createSelector(
  (state: RootState) => selectDropdownOptions(state, "schemas"),
  (options) => options.sort((a, b) => sortAscInsensitive(a.label, b.label))
);

const selectSchemasEntity = (state: RootState, id: string) =>
  selectEntity(state.schemas, id);

export const selectSchemaTargetFolderPath = createSelector(
  selectSchemasEntity,
  getEntityTargetFolderPath
);

export const selectPresentSchemas = createSelector(
  (state: RootState) => state.schemas,
  (schemas: GenericState<Schema>) => schemas.map((schema) => schema.present)
);
