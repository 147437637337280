import React, { FC, useState } from "react";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { LoadingButton } from "@mui/lab";
import { Box, Menu, MenuItem } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

import { IndexedDBExporter } from "./IndexedDBExporter";
import { LogExporter } from "./LogExporter";

const ExporterMenu: FC<{ isExporting: boolean }> = ({
  children,
  isExporting,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box height="20px" display="flex">
      <LoadingButton
        aria-controls={open ? "exporter-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        sx={{
          fontSize: "11px",
          margin: "auto",
          padding: "0 !important",
          color: "white",
          minWidth: "42px !important",
        }}
        loading={isExporting}
        loadingIndicator={
          <CircularProgress
            sx={{ color: "white" }}
            size={14}
            data-testid="exporters-loading"
          />
        }
        startIcon={
          <FileDownloadIcon
            sx={{
              height: "16px",
              width: "16px",
              margin: "auto",
            }}
          />
        }
        id="exporter-button"
        onClick={handleClick}
      >
        Support
      </LoadingButton>
      <Menu
        id="exporter-menu"
        aria-labelledby="exporter-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        sx={{
          background: "main",
        }}
      >
        {React.Children.map(children, (child) => (
          <MenuItem onClick={handleClose}>{child}</MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

export const Exporters: FC = () => {
  const [isExporting, setIsExporting] = useState(false);

  return (
    <ExporterMenu isExporting={isExporting}>
      <LogExporter />
      <IndexedDBExporter setIsExporting={setIsExporting} />
    </ExporterMenu>
  );
};
